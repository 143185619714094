.text-red {
  color: red !important;
}

.power-section-wrapper {
  position: relative;
  overflow: hidden;

  .bg-layer {
    width: perc(1293);
    max-width: 1293px;
    min-width: 428px;
    left: 0;
    top: 0;
    transform: translate(-12.2%, -13.6%);
    opacity: 0.1;
    @media (max-width: 650px) {
      opacity: 0.24;
    }
  }
}

.power-section {
  padding: ac(110px, 56px) 0 30px;
  overflow: hidden;

  &-thumbnail {
    position: relative;
    width: ac(534px, 320px);
    align-self: flex-start;

    &::before {
      content: '';
      display: block;
      padding-top: 97.37%;
    }

    &-wrapper {
      width: perc(395, 534);
      position: absolute;
      overflow: hidden;
      border-radius: ac(20px, 15px);
      top: 0;
      right: 0;
      z-index: 0;

      &::before {
        content: '';
        display: block;
        height: 100%;
        position: relative;
        padding-top: 118.27%;
      }

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s;
      }

      &:nth-of-type(2) {
        width: perc(318, 530);
        height: auto;
        right: auto;
        bottom: 0;
        top: auto;
        left: 0;
        z-index: 1;

        &::before {
          padding-top: 106.29%;
        }
      }
    }
  }

  .cont {
    display: flex;
    gap: ac(136px, 40px);
  }

  .power-section-text {
    flex: 1;
    max-width: 605px;
  }

  .section-descr {
    font-size: ac(26px, 18px);
    line-height: 1.3846;
    font-family: var(--font-second);
    font-weight: 300;
    @media (max-width: 650px) {
      line-height: 1.5555;
    }

    &:not(:last-child) {
      margin-bottom: ac(50px, 24px);
    }

    p {
      margin-bottom: 15px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.status-box-list-wr {
  flex: 1;
  max-width: 500px;
  border-radius: 20px;
  background: var(--off-white);
  padding: ac(40px, 24px) ac(50px, 20px);
  align-self: flex-start;

  h3 {
    font-size: ac(30px, 26px);
    line-height: 1.26;
    margin-bottom: ac(35px, 28px);
    @media (max-width: 650px) {
      line-height: 1.269;
    }
  }
}

.status-box-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 19px;
}

.status-box {
  &-item {
    position: relative;
    transform: translate(0);
    color: var(--off-white);
    font-size: 30px;
    line-height: 1.26;
    margin-bottom: ac(5px, 13px);
    display: flex;
    border-radius: 50px;
    overflow: hidden;
  }

  .count-up-wr {
    padding: ac(21px, 9.1px) ac(5px, 20px) ac(21px, 9.1px) ac(15px, 20px);
    border-radius: 50px;
    background-color: var(--sky-blue);
    min-width: 92px;
    display: flex;
  }

  .count-up-suffix {
    font-size: 72%;
    align-self: flex-start;
    transform: translateY(10%);
  }

  &-progress {
    /*--status:0px;*/
    width: 0;
    min-width: fit-content;
    height: 100%;
    background: var(--sky-blue);
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    border-radius: 50px;
    transition: width 1.5s 0.2s;
  }

  &-text {
    font-size: ac(17px, 16px);
    line-height: 1.312;
    padding-left: 15px;
    @media (max-width: 650px) {
      padding-left: 20px;
      line-height: 1.25;
    }
  }
}

.animated-count {
  .status-box-progress {
    width: var(--status);
  }
}

.solutions-section {
  padding: ac(25px, 50px) 0 ac(140px, 33px);

  .title {
    text-align: center;
    margin-bottom: ac(60px, 40px);
  }
}

.solutions-list {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: ac(30px, 12px);
}

.solutions-card {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  color: var(--off-white);
  padding: 20px ac(40px, 20px, 1200) ac(40px, 28px, 1200);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: ac(394px, 327px);
  transform: translateX(0);

  &-thumbnail {
    position: absolute;
    inset: 0;
    z-index: -1;
    overflow: hidden;
    border-radius: 20px;
    transform: translateX(0);

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
      z-index: 2;
    }

    &::before {
      background: var(--ice-blue);
      mix-blend-mode: multiply;
      z-index: 1;
    }

    &::after {
      height: 200%;
      background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.5) 50%,
        rgba(0, 0, 0, 0) 100%
      );
      transition: transform 0.8s;
      will-change: transform;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: saturate(0);
    }
  }

  &:hover {
    .solutions-card-thumbnail {
      &::after {
        transform: translateY(-50%);
      }
    }
  }

  &-icon {
    width: ac(51px, 48px);
    margin-bottom: ac(20px, 11px);
    position: relative;

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &-content {
    padding: ac(15px, 8px) 0 0;
    display: none;
  }

  &-title {
    font-size: ac(40px, 28px);
    font-weight: 600;
    line-height: 1.25;
    @mixin max-line-length 2;
  }

  &-text {
    @mixin max-line-length 3;
    width: 100%;
    margin-bottom: 30px;
    font-size: ac(18px, 17px);
    font-weight: 300;
    font-family: var(--font-second);
    line-height: 1.33;
  }

  .btn {
    width: 258px;
    max-width: 100%;
  }
}

.roles-section {
  background: var(--sky-blue);
  padding: ac(110px, 48px) 0;

  .title {
    /* max-width: ac(712px, 442px);*/
    margin-bottom: ac(-30px, 20px, 1250);
  }

  .btn {
    min-width: 258px;
  }

  .motto-wr {
    display: flex;
    justify-content: space-between;
    gap: 32px;
    align-items: flex-end;
    padding-bottom: 29px;

    .swiper-buttons {
      margin-right: auto;
      margin-left: 0;
    }

    .section-motto {
      flex: 1;
      max-width: 712px;
      font-weight: 300;
      font-family: var(--font-second);
      line-height: 1.27;
      margin-bottom: 29px;
    }
  }

  .btn {
    margin-top: 40px;
  }
}

.roles-swiper-wr {
  .swiper {
    overflow: visible;
  }

  .swiper-slide {
    width: fit-content;
    max-width: 100%;
  }
}

.role-card {
  width: ac(288px, 263px);
  border-radius: 20px;
  background: var(--white);
  padding: ac(33px, 27px) ac(30px, 25px) ac(44px, 24px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: ac(288px, 263px);
  transform-style: preserve-3d;
  transition: background-color 0.3s, transform 0.3s;

  &-title {
    font-size: ac(25px, 26px);
    margin-bottom: 20px;
    transform: translateZ(10px);
    transition: color 0.5s, text-shadow 0.3s;
    text-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0);
    @mixin max-line-length 3;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
    transform: translateZ(10px);
  }

  li {
    font-size: ac(17px, 18px);
    line-height: 1.235;
    transition: color 0.5s, text-shadow 0.3s;
    text-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0);
    @mixin max-line-length 1;
    @media (max-width: 650px) {
      line-height: 1.27;
    }
  }

  &:hover {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3);
    transform: scale(1.05);

    .role-card-title {
      text-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    }

    li {
      text-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    }
  }
}

.client-ts-section {
  padding: ac(173px, 56px) 0 ac(140px, 32px);
  overflow: hidden;

  .bg-layer {
    width: perc(1293);
    max-width: 1293px;
    top: 35%;
    left: 66.1%;
    transform: translate(-50%, -50%);
    opacity: 0.1;
    @media (max-width: 650px) {
      width: 1293px;
      left: 100%;
      transform: translate(-10%, -24%);
      opacity: 0.25;
    }
  }

  .cont {
    display: flex;
    justify-content: space-between;
    gap: ac(45px, 40px, 1300);
  }

  .title {
    margin-bottom: ac(28px, 14px);
    font-size: ac(70px, 40px, 1100);
  }
}

.client-ts-swiper-wr {
  width: 568px;
  max-width: 47%;
  flex-shrink: 0;
  position: relative;

  .swiper-buttons {
    position: absolute;
    top: 0;
    left: ac(43px, 24px);
    transform: translate(0, -50%);
    z-index: 2;
  }

  .swiper-slide {
    display: flex;
    height: auto;
  }
}

.client-ts-card {
  width: 100%;
  border-radius: 20px;
  padding: ac(53px, 48px) ac(43px, 24px) ac(43px, 41px);
  background: var(--deep-blue);
  color: var(--off-white);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: var(--font-second);
  font-weight: 300;

  &-text {
    width: 100%;
    --line-count: 12;
    --fz: ac(24px, 20px);
    --line-height: 1.25;
    line-height: var(--line-height);
    font-weight: 300;
    font-size: var(--fz);
    max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
    margin-bottom: ac(51px, 25px);

    p {
      margin-left: 0 !important;

      &:not(:last-child) {
        margin-bottom: calc(ac(24px, 20px) * 1.25);
      }
    }
  }
}

.client-logos-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 140px));
  justify-content: space-between;
  grid-gap: 0 20px;
}

.client-logos-wrapper {
  margin-top: -3px;
  flex: 1;
}

.client-logo {
  width: 100%;
  display: flex;
  padding: 10px 0;
  height: ac(95px, 100px);
  /*border-top: 1px solid var(--off-white);
  border-bottom: 1px solid var(--off-white);*/

  picture {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }
}

.industry-section {
  background: var(--off-white);
  padding: ac(110px, 48px) 0 ac(110px, 55px);
  transform: translate(0);
  overflow: hidden;

  .section-thumbnail {
    position: absolute;
    width: perc(648);
    min-width: 431px;
    max-width: 648px;
    overflow: hidden;
    border-radius: 50%;
    z-index: -1;
    left: 79.7%;
    bottom: 0;
    transform: translate(-20%, 16%) scale(0.5);
    opacity: 1;
    transition: 0.5s;

    &.aos-animate {
      transform: translate(-50%, 16%) scale(1);
      opacity: 1;
    }

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .section-description {
    max-width: 606px;
    width: 100%;
    font-weight: 300;
    padding-bottom: 50px;
    line-height: 1.27;
    font-family: var(--font-second);
    @media (max-width: 650px) {
      line-height: 1.5555;
    }
  }
}

.industry-swiper-wr {
  .swiper {
    width: ac(606px, 445px, 1024, 1440);
    margin: 0;
    overflow: visible;

    &:not(.swiper-initialized) {
      & + .swiper-buttons {
        display: none;
      }
    }
  }

  .swiper-slide {
    display: flex;
    height: auto;
    justify-content: flex-start;
    overflow: visible !important;

    &:last-of-type {
      .industry-card {
        width: calc(46% + ac(0px, 20px, 1024, 1440));

        @media (min-width: 1400px) {
          transform: translateX(-7px);
        }

        &-inner {
          width: 100%;
        }

        &.active {
          width: 54%;
        }
      }
    }

    &:nth-last-of-type(2) {
      .industry-card {
        width: calc(100% - 40px);
      }
    }
  }

  .swiper-buttons {
    display: none;
  }

  @media (max-width: 1023px) {
    .swiper {
      width: 328px;
      max-width: 100%;
    }

    .swiper-slide {
      &:last-of-type {
        .industry-card {
          width: 80% !important;

          &-inner {
            width: 100%;
          }
        }
      }

      &:nth-last-of-type(2) {
        .industry-card {
          width: 100%;
        }
      }

      .industry-card {
        &-inner {
          width: 70%;
        }

        .circle-btn {
          font-size: 19px;
          padding-bottom: 3px;
        }

        &-footer {
          padding-right: 12px;
        }
      }

      &.swiper-slide-active {
        .industry-card {
          background: var(--sky-blue);

          .circle-btn {
            opacity: 1;
          }
        }
      }
    }

    .swiper-buttons {
      display: flex;
      margin-left: 0;
      margin-bottom: 20px;
    }
  }
}

.industry-card {
  width: 100%;
  border-radius: 20px;
  background: var(--white);
  padding: ac(30px, 24px);
  display: flex;
  justify-content: flex-start;
  transition: 0.3s;
  min-height: ac(198px, 174px);
  filter: drop-shadow(0 7px 25px rgba(0, 0, 0, 0.06));

  &-inner {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @media (min-width: 1024px) {
    &.active {
      background: var(--sky-blue);

      .circle-btn {
        opacity: 1;
      }
    }
  }

  &-title {
    font-size: ac(35px, 28px, 1024, 1440);
    font-weight: 600;
    line-height: 1.25;
    margin-bottom: 20px;
  }

  .industry-card-icon {
    height: 46px;

    img {
      object-fit: contain;
      object-position: left;
      max-height: 100%;
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 15px;
  }

  .circle-btn {
    width: ac(67px, 48px);
    height: ac(67px, 48px);
    font-size: ac(22px, 16px);
    opacity: 0;
    opacity: 0;
    transition: opacity 0.3s;
    display: none;
  }
}

.redi-wrapper {
  position: relative;
  overflow: hidden;
  transform: translate(0);

  .bg-layer {
    opacity: 0.1;
    bottom: 0;
    left: 0;
    transform: translate(-12%, 22.5%);
    z-index: -1;
    min-width: 427px;
    @media (max-width: 650px) {
      opacity: 0.2;
    }
  }
}

.redi-section {
  padding: ac(141px, 32px) 0 ac(70px, 40px);

  .cont {
    display: flex;
    gap: 40px;
    justify-content: space-between;
  }

  &-text {
    width: 500px;
    flex-shrink: 0;
    max-width: 42%;
    padding-top: 24px;
  }

  .section-description {
    font-weight: 300;
    line-height: 1.277;
    font-family: var(--font-second);
    margin-top: -1px;
    @media (max-width: 650px) {
      line-height: 1.5555;
    }
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: ac(30px, 13px);
    grid-auto-rows: 1fr;
    flex: 1;
    max-width: 606px;
  }
}

.redi-card {
  display: flex;
  flex-direction: column;
  gap: 20px;

  font-weight: 300;
  padding: 30px;
  border-radius: 20px;
  background: var(--deep-blue);
  color: var(--off-white);
  transition: 0.3s;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0);
  font-family: var(--font-second);
  cursor: default;

  &-title {
    font-size: ac(30px, 26px);
    line-height: 1.26;
    font-family: var(--font-main);
    font-weight: 400;
    @media (max-width: 650px) {
      line-height: 1.2692;
    }
  }

  &-text {
    --fz: ac(16px, 18px);
    --line-count: 5;
    --line-height: 1.25;
    width: 100%;
    line-height: var(--line-height);
    font-size: var(--fz);
    max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
    @media (max-width: 650px) {
      --line-height: 1.55;
    }

    &.simplebar-scrollable-y {
      /*    padding-right: 15px;*/
    }

    .simplebar-scrollbar::before {
      /* background-color: var(--orange);
             color: var(--orange);
             opacity: 1!important;
             left: 1px;
             right: 1px;*/
    }

    .simplebar-track.simplebar-vertical {
      /*  background: rgba(255,255,255, 0.2)!important;
              opacity: 1!important;
              width: 5px;
              border-radius: 5px;
              right: -8px;*/
    }
  }

  &:hover {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3);
    transform: scale(1.08);
  }
}

.news-section {
  padding: ac(72px, 49px) 0 ac(110px, 53px);

  .title-wr {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 40px;
    padding-bottom: 79px;

    .title {
      margin: 0;
    }

    .btn {
      margin-bottom: 2px;
    }
  }
}

.news-swiper-wr {
  .swiper-buttons {
    display: none;
  }

  .swiper {
    overflow: visible;

    &:not(.swiper-initialized) {
      .swiper-wrapper {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-gap: ac(30px, 8px);
      }

      .swiper-slide {
        &:nth-of-type(6n + 1) {
          grid-column: 1/3;

          .news-card-title {
            font-size: ac(25px, 20px);
            line-height: 1.28;
          }
        }

        &:nth-of-type(6n + 5) {
          grid-column: 2/4;

          .news-card-title {
            font-size: ac(25px, 20px);
            line-height: 1.28;
          }
        }
      }
    }
  }
}

.news-card {
  width: 100%;
  min-height: ac(190px, 218px);
  position: relative;
  transform: translate(0);
  padding: ac(20px, 28px) 20px ac(20px, 24px);
  color: var(--off-white);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  transition: 0.3s;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0);
  cursor: pointer;

  &-thumbnail {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
    border-radius: 20px;

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.5s;
      max-width: revert;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
      z-index: 1;
      background: linear-gradient(
        88.15deg,
        rgba(4, 13, 29, 0.7) 9.84%,
        rgba(4, 13, 29, 0.25) 96.87%
      );
    }
  }

  &-header {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
    justify-content: space-between;
    font-size: ac(14px, 16px);
    line-height: 1.28;
    @media (max-width: 650px) {
      line-height: 1.25;
    }
  }

  &-title {
    font-size: ac(18px, 20px);
    line-height: 1.27;
    font-weight: 400;
    @mixin max-line-length 3;

    @media (max-width: 650px) {
      font-weight: 600;
      line-height: 1.25;
    }
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.25);
  }

  &.news-card-action {
    min-height: ac(285px, 220px);

    .news-card {
      &-header {
        font-size: ac(18px, 16px);
      }

      &-title {
        font-size: ac(35px, 20px);
        font-weight: 400;
        line-height: 1.26;
      }
    }
  }
}

@media (max-width: 1200px) {
  .redi-section {
    &-text {
      width: 100%;
      max-width: 100%;
    }

    &-grid {
      margin: 0 auto;
    }

    .cont {
      flex-direction: column;
    }
  }

  .status-box-list-wr {
    margin: 0 auto;
  }
}

@media (max-width: 992px) {
  .power-section {
    &-text {
      max-width: 100%;
    }

    .cont {
      flex-direction: column;
    }

    &-thumbnail {
      width: 450px;
      max-width: 100%;
      margin: 0 auto;
    }

    &.power-section-about {
      .section-descr {
        margin-bottom: 0;
      }
    }
  }

  .solutions-list {
    grid-template-columns: 1fr;
    width: 327px;
    max-width: 100%;
    margin: 0 auto;
  }

  .solutions-card {
    &-title {
      margin-bottom: 2px;
    }

    &-content {
      display: block;

      .flex {
        display: none;

        .btn {
          margin-top: 10px;
        }
      }
    }

    &-text {
      margin-bottom: 0;
    }

    &-thumbnail {
      &::after {
        transform: translateY(-50%);
        background: #000;
        opacity: 0.4;
      }
    }
  }

  .news-section .title-wr {
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    padding-bottom: 20px;
  }

  .news-swiper-wr {
    position: relative;
    padding-bottom: 99px;

    .swiper-buttons {
      display: flex;
      margin-left: 0;
    }

    .btn {
    }

    .swiper-slide {
      width: 327px;
      max-width: 100%;
    }
  }

  .news-section .title-wr {
    .btn-wr {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 20px) !important;
      transition: all 0.8s !important;
      width: 100%;

      &.aos-animate {
        transform: translate(-50%, 0) !important;
      }
    }
  }

  .client-ts-section {
    .cont {
      flex-direction: column;
    }
  }

  .client-ts-swiper-wr {
    width: 500px;
    max-width: 100%;
    margin: 0 auto;
  }

  .client-logos-wrapper {
    width: 600px;
    max-width: 100%;
    margin: 0 auto;
  }
}

@media (max-width: 650px) {
  .hero-section .cont {
    justify-content: flex-end;
  }

  .office-swiper-wr {
    .swiper-slide {
      width: fit-content;
      max-width: 100%;

      &:last-of-type {
        width: 100%;
      }
    }
  }

  .redi-section-grid {
    grid-template-columns: 1fr;
    max-width: 100%;

    .redi-card {
      max-width: calc(100vw - 48px);
    }
  }

  .status-box-list-wr {
    padding-bottom: 30px;
  }

  .solutions-section .title {
    text-align: left;
  }

  .roles-section {
    .motto-wr {
      flex-direction: column-reverse;
      padding-bottom: 20px;

      .section-motto {
        line-height: 1.55555;
      }
    }
  }

  .client-logos-grid {
    grid-template-columns: 1fr 1fr;
  }

  .industry-section .section-description {
    padding-bottom: 80px;
  }

  .industry-section .section-thumbnail {
    border-radius: 0;
    mask-image: url('../../assets/images/industry-thumbnail-mask.png');
    mask-size: 100% 100%;
    mask-repeat: no-repeat;
    right: 0;
    bottom: 0;
    left: auto;
    transform: translate(30%, 18.1%) scale(0.5);
    transform-origin: right bottom;

    img {
      transform: translate(-15%, 0.1%);
    }

    &.aos-animate {
      opacity: 1;
      transform: translate(30%, 18.1%) scale(1);
    }
  }

  .redi-section {
    .title {
      margin-bottom: 22px;
    }
  }

  .redi-card {
    padding: 30px 24px;
    gap: 15px;

    .title {
      font-weight: 500;
    }
  }
}

@media (max-width: 420px) {
  .client-ts-section .title {
    padding-right: ac(110px, 50px, 320, 420);
  }
}

@media (max-width: 374px) {
  .industry-card-title {
    font-size: 22px;
  }

  .footer-copyright {
    font-size: 13px;
  }
}
