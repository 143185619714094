.pass-sections-wrapper {
  position: relative;
  .bg-layer {
    width: perc(1293);
    top: 89px;
    left: -156px;
  }
}

.passionate-section {
  padding: ac(80px, 32px) 0 ac(70px, 40px);
  &-wrapper {
    display: flex;
    align-items: flex-end;
    gap: ac(65px, 30px);
  }

  .title {
    font-size: ac(70px, 28px);
    line-height: 1.26;
    padding: ac(24px, 20px) ac(49px, 20px);
    background: var(--white);
    border-radius: 20px;
    max-width: 1150px;
    margin: 0 auto ac(-127px, -50px);
    position: relative;
    z-index: 1;
  }

  &-thumbnail {
    position: relative;
    overflow: hidden;
    width: ac(571, 320px);
    flex-shrink: 0;
    max-width: 100%;
    align-self: stretch;
    border-radius: ac(49px, 20px);
    margin-bottom: ac(30px, 0px);

    &::before {
      content: '';
      display: block;
      padding-top: 73.9%;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
    }
  }
  &-card-wr {
    padding-top: ac(140px, 80px);
    flex: 1;
    min-width: 0;
  }
  &-card {
    border-radius: 20px;
    background: var(--midnight);
    padding: 30px;
    color: var(--white);
    width: 100%;
    i {
      font-size: 32px;
      line-height: 1;
      display: block;
      margin-bottom: 10px;
      color: var(--off-white);
    }
    &-title {
      font-size: ac(30px, 22px);
      line-height: 1.26;
      margin-bottom: 22px;
      color: var(--off-white);
    }
    &-description {
      --line-count: 9;
      --fz: ac(18px, 16px);
      --line-height: 1.25;
      font-family: var(--font-second);
      font-weight: 300;
      line-height: var(--line-height);
      font-size: var(--fz);
      max-height: calc(var(--fz) * var(--line-height) * var(--line-count));

      &.simplebar-scrollable-y {
        padding-right: 15px;
      }
    }
  }
}

.count-section {
  padding: ac(71px, 35px) 0 ac(139px, 50px);
}
.count-swiper-wr {
  .swiper {
    overflow: visible;
    width: ac(489px, 330px);
    max-width: 100%;
    margin: 0;
    &-slide {
      display: flex;
      justify-content: flex-end;
      height: auto;
      &:nth-of-type(3n + 1) {
        .count-card {
          background: var(--deep-blue);
          color: var(--off-white);
        }
      }
      &:nth-of-type(3n + 2) {
        .count-card {
          background: var(--sky-blue);
        }
      }
      &:nth-of-type(3n + 3) {
        .count-card {
          background: var(--ice-blue);
        }
      }
      &:nth-last-of-type(2) {
        .count-card {
          width: calc(100% - ac(31px, 0px));
          padding-right: ac(106px, 70px);
        }
      }

      &:last-of-type {
        .count-card {
          width: calc(100% - ac(99px, 0px));
          padding-right: 20px;
          margin-right: ac(45px, 20px);
        }
      }
      &.swiper-slide-active {
        .count-card {
          /*width: 100% !important;*/
        }
      }
    }
    &-buttons {
      display: none;
      @media (max-width: 768px) {
        display: flex;
        .swiper-button {
          margin-top: 35px;
        }
      }
    }
  }
}

.count-card {
  width: 100%;
  background: var(--sky-blue);
  padding: ac(40px, 30px) 135px ac(40px, 30px) ac(40px, 20px);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  color: var(--midnight);
  transition: width 0.5s;
  min-height: ac(308px, 220px);
  &-counter {
    width: 100%;
    display: flex;
    font-size: ac(70px, 40px);
    font-weight: 400;
    line-height: 1.26;
  }
  &-text {
    width: 100%;
    font-weight: 600;
    --line-count: 4;
    --fz: ac(40px, 22px);
    --line-height: 1.26;
    line-height: var(--line-height);
    font-size: var(--fz);
    max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
    text-align: right;

    &.simplebar-scrollable-y {
    }
  }
  @media (max-width: 768px) {
    padding-right: 70px;
    margin-right: 0 !important;
    width: 100% !important;
  }
}

.approach-section.approach-section-join-us {
  padding: ac(70px, 40px) 0 ac(47px, 40px);
}
.action-wrapper {
  .bg-layer {
    top: 50%;
    right: 0;
    transform: translate(12.2%, 15.3%);
  }
}

.blogs-section {
  padding: ac(140px, 40px) 0;
  .title {
    margin-bottom: 33px;
  }
  &-description {
    max-width: 847px;
    font-size: ac(26px, 20px);
    font-weight: 300;
    font-family: var(--font-second);
    line-height: 1.3846;
    margin-bottom: ac(5px, 40px);
  }
  .btn {
    margin-bottom: 32px;
  }
  .swiper-button {
    margin-top: 35px;
  }
}
.blogs-swiper-wr {
  .swiper {
    overflow: visible;
  }
}

.benefits-section {
  padding: ac(140px, 50px) 0 ac(110px, 40px);
  .title {
    margin-bottom: 50px;
  }
  &-description {
    max-width: 817px;
    margin-bottom: ac(59px, 35px);
    font-size: ac(26px, 20px);
    line-height: 1.3846;
    font-family: var(--font-second);
    font-weight: 300;
  }
}

.benefits-swiper-wr {
  .swiper {
    width: 100%;
    height: 396px;
    &-slide {
      display: flex;
      height: calc((100% - 30px) / 2);
      @media (max-width: 1299px) {
        height: calc((100% - 15px) / 2);
      }
    }
    &-button {
      margin-top: 35px;
    }
  }
}
.benefit-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--off-white);
  padding: 30px;
  gap: 10px;
  justify-content: space-between;
  border-radius: 20px;
  &-title {
    width: 100%;
    font-weight: 600;
    --line-count: 3;
    --fz: ac(20px, 16px);
    --line-height: 1.26;
    line-height: var(--line-height);
    font-size: var(--fz);
    max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
    transition: 0.3s;

    &.simplebar-scrollable-y {
      padding-right: 15px;
    }
  }
  &-icon {
    width: 43px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    img {
      max-height: 100%;
      max-width: 100%;
      transition: filter 0.3s ease;
    }
  }

  &:hover {
    .benefit-card-icon img {
      filter: invert(33%) sepia(95%) saturate(749%) hue-rotate(179deg)
        brightness(92%) contrast(89%);
    }

    .benefit-card-title {
      color: #49a6e8;
    }
  }
}

@media (max-width: 750px) {
  .passionate-section {
    .title {
      margin-bottom: 35px;
      box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.05);
    }
    &-wrapper {
      flex-direction: column;
    }
    &-thumbnail {
      width: 500px;
      max-width: 100%;
      margin: 0 auto;
    }
    &-card-wr {
      padding-top: 0;
      width: 500px;
      margin: 0 auto;
      max-width: 100%;
    }
  }
}
