input, textarea{
    @mixin transparent-bg-input var(--black);
    border-radius: 41px;
    background: var(--off-white);
    padding: 10px 20px;
    font-family: var(--font-second);
    font-weight: 300;
    font-size: ac(22px, 18px);
    &::placeholder{
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        opacity: 0.5;
        font-weight: inherit;
    }
}
input{
    color: var(--black);
    min-height: ac(67px, 57px);
}
textarea{
    height: 150px;
    padding-top: 19px;
    resize: none;
}

.input-wr{
    display: flex;
    flex-direction: column;
    gap: 14px;
    font-size: 17px;
    font-weight: 400;
    input, textarea{
        width: 100%;
        padding-left: ac(35px, 20px);
    }
}

.search-wr{
    position: relative;
    display: block;
    input{
        padding-left: ac(80px, 64px);
    }
}


@media (max-width: 374px) {
    .search-wr input{
        padding-left: 58px;
        font-size: 14px;
    }
}


    .upload-btn-wr {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        transition: 0.3s;
        cursor: pointer;
        justify-content: center;
        input {
            display: none !important;
        }

        .upload-btn {
            font-size: ac(20px, 16px);
            padding: 5px 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            min-height: 72px;
            border: 1px dashed var(--midnight);
            border-radius: 5px;
            cursor: pointer;
            transition: 0.3s;
            color: var(--midnight);

            &:hover {
                background: var(--sky-blue);
                color: var(--white);
            }

        }

        .cv_file_name {
            font-size: ac(16px, 14px);
            line-height: 1;
            @mixin max-line-length-one;
        }
        &:hover{
            color: var(--sky-blue);
        }
    }

    label.checkbox-wr, .checkbox-wr {
        --checkbox-icon-size: 18px;
        --checkbox-mark-color: var(--white);
        display: flex;
        gap: 12px;
        cursor: pointer;
        transition: 0.3s;
        font-size: 16px;
        input{
            display: none;
            &:checked + .checkbox-icon{
                &::before{
                    opacity: 1;
                }
            }
        }

        .checkbox-icon{
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            width: var(--checkbox-icon-size);
            height: var(--checkbox-icon-size);
            flex-shrink: 0;
            border: 2px solid var(--midnight);

            &::before{
                content: '';
                display:block;
                position: absolute;
                left: -2px;
                top: -2px;
                width: var(--checkbox-icon-size);
                height: var(--checkbox-icon-size);
                background: var(--checkbox-mark-color);
                background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V22C0 22.5304 0.210714 23.0391 0.585786 23.4142C0.960859 23.7893 1.46957 24 2 24H22C22.5304 24 23.0391 23.7893 23.4142 23.4142C23.7893 23.0391 24 22.5304 24 22V2C24 1.46957 23.7893 0.960859 23.4142 0.585786C23.0391 0.210714 22.5304 0 22 0ZM17.7075 9.7075L10.7075 16.7075C10.6146 16.8005 10.5043 16.8742 10.3829 16.9246C10.2615 16.9749 10.1314 17.0008 10 17.0008C9.86858 17.0008 9.73846 16.9749 9.61706 16.9246C9.49566 16.8742 9.38537 16.8005 9.2925 16.7075L6.2925 13.7075C6.10486 13.5199 5.99944 13.2654 5.99944 13C5.99944 12.7346 6.10486 12.4801 6.2925 12.2925C6.48014 12.1049 6.73464 11.9994 7 11.9994C7.26536 11.9994 7.51986 12.1049 7.7075 12.2925L10 14.5863L16.2925 8.2925C16.3854 8.19959 16.4957 8.12589 16.6171 8.07561C16.7385 8.02532 16.8686 7.99944 17 7.99944C17.1314 7.99944 17.2615 8.02532 17.3829 8.07561C17.5043 8.12589 17.6146 8.19959 17.7075 8.2925C17.8004 8.38541 17.8741 8.49571 17.9244 8.6171C17.9747 8.7385 18.0006 8.86861 18.0006 9C18.0006 9.13139 17.9747 9.2615 17.9244 9.3829C17.8741 9.50429 17.8004 9.61459 17.7075 9.7075Z' fill='#49A6E8'/%3E%3C/svg%3E%0A");
                opacity: 0;
                transition: opacity 0.3s;
            }
        }
        a{
            display: inline;
            text-decoration: underline;

            &:hover{
                text-decoration: none;
            }
        }
        &:hover{
            color: var(--sky-blue);
        }
    }