.simple-select {
  --padding: 9px 9px 9px 35px;
  position: relative;
  flex-shrink: 0;
  width: 300px;
  font-size: ac(22px, 18px);
  font-weight: 300;
  font-family: var(--font-second);
  color: #000;
  z-index: 50;

  &-item {
    height: ac(67px, 57px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    border-radius: 67px;
    background: var(--off-white);
    padding: var(--padding);
    cursor: pointer;

    span {
      min-width: 0;
      flex-grow: 1;
      @mixin max-line-length 1;
    }
  }

  &-toggle {
    display: flex;
    width: 49px;
    height: 49px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    border: 1px solid var(--midnight);
    flex-shrink: 0;

    i {
      font-size: 8px !important;
    }

    &:hover {
      background-color: var(--sky-blue);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      border-color: var(--sky-blue);
      color: var(--white);
    }
  }

  &-dropdown {
    position: absolute;
    left: 0;
    top: 100%;
    background: var(--off-white);
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    display: none;
    animation: simpleSelectFadeIn forwards 0.3s;
    z-index: 2;
  }

  &-list {
    width: 100%;
    max-height: ac(285px, 260px);
    overflow-y: auto;

    &-item {
      width: 100%;
      padding: 15px 20px 15px 35px;
      cursor: pointer;
      display: block;

      span {
        @mixin max-line-length 1;
      }

      &:hover {
        color: var(--white);
        background: var(--sky-blue);
      }
    }

    input {
      display: none;

      &:checked + .simple-select-list-item {
        background: var(--ice-blue);
      }
    }
  }
  &.active {
    .simple-select-dropdown {
      display: block;
    }
  }
  @keyframes simpleSelectFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
