.news-article-page {
}

.hero-section-article {
  min-height: 700px;
  padding-bottom: 36px;

  .cont {
    justify-content: flex-end;
  }

  .hero-caption {
    &-date {
      margin-bottom: 20px;
      font-size: 17px;
      line-height: 1.26;
    }

    .title {
      margin-bottom: 50px;
      font-size: ac(90px, 35px);
    }
  }

  .section-bg {
    &::before {
      background: linear-gradient(
        88.15deg,
        rgba(4, 13, 29, 0.7) 9.84%,
        rgba(4, 13, 29, 0.25) 96.87%
      );
    }
  }

  .socials-buttons {
    align-items: center;
    display: flex;
    gap: 20px;

    span {
      font-size: ac(20px, 18px);
    }
  }
}

.article-section {
  padding: ac(110px, 45px) 0 ac(50px, 30px);
  overflow: hidden;
  .bg-layer {
    left: 0;
    top: 0;
    transform: translate(-12%, -1%);
  }

  &-wrapper {
    max-width: 818px;
    margin: 0 auto;
  }

  .content-elements {
    font-size: ac(18px, 16px);
    line-height: 1.25;
    font-family: var(--font-second);
    font-weight: 300;

    p {
      margin-bottom: 15px;
      margin-top: 27px;

      &:first-of-type {
        font-family: var(--font-second);
        font-weight: 300;
        font-size: ac(26px, 20px);
        line-height: 1.3846;
        margin-bottom: 50px;
        margin-top: 0;
      }

      & + h3 {
        margin-top: 24px;
      }

      & + p {
        margin-top: 18px;
      }
    }

    h2 {
      font-size: ac(35px, 25px);
      line-height: 1.26;
      margin-top: ac(50px, 35px);
      margin-bottom: ac(26px, 20px);
      font-family: var(--font-main);
      font-weight: 400;
    }

    h3 {
      color: var(--deep-blue);
      font-size: ac(26px, 20px);
      font-weight: 300;
      line-height: 1.384;
      margin-bottom: 15px;

      & + p {
        margin-top: 0;
      }
    }
    h4,
    h5,
    h6 {
      font-size: inherit;
      font-weight: 400;
      margin: 10px 0 15px;
    }

    br + p {
      margin-top: ac(14px, 0px);
    }

    ul,
    ol {
      padding: 0 0 0 21px;
      margin: 20px 0;
      list-style: disc;
    }
    ol {
      list-style: decimal;
    }

    a {
      display: inline;
      text-decoration: underline;
      transition: 0.3s;

      &:hover {
        color: var(--sky-blue);
      }

      &.btn {
        display: inline-flex !important;
        color: var(--off-white) !important;
        font-size: 16px !important;
        cursor: pointer;
        text-decoration: none !important;
        margin-top: 10px;

        span {
          text-decoration: none !important;
        }

        &:hover {
          color: var(--off-white) !important;
        }

        &.btn-big {
          min-width: 258px;
        }

        @media (max-width: 450px) {
          width: 100%;
        }
      }
    }
  }

  .socials-buttons {
    margin-top: 50px;
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: ac(20px, 18px);
  }
}

.blogs-section.blogs-section-article {
  padding-top: ac(90px, 30px);
  padding-bottom: ac(108px, 45px);

  .bg-layer {
    right: 0;
    bottom: 0;
    transform: translate(12.3%, 40.8%);
  }

  .title-wr {
    display: flex;
    justify-content: space-between;
    gap: 32px;
    flex-wrap: wrap;
    margin-bottom: ac(81px, 35px);
    align-items: center;

    .title {
      margin-bottom: 0;
    }

    .btn {
      margin-bottom: 0;
    }
  }
}

.blogs-swiper-wr-article {
  .swiper {
    overflow: visible;
  }
}

@media (max-width: 768px) {
  .hero-section-article {
    min-height: calc(100 * var(--vh, 1vh));
    .cont {
      justify-content: center;
    }
  }
}
