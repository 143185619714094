:root {
  --pp-main: var(--midnight);
  --pp-main-rgb: 112 112 112;

  --pp-black: var(--midnight);
  --pp-black-rgb: 0 0 0;

  --pp-white: #ffffff;
  --pp-white-rgb: 255 255 255;

  --pp-dark-blue: var(--deep-blue);
  --pp-dark-blue-rgb: 14 17 39;

  --pp-blue: var(--sky-blue);
  --pp-blue-rgb: 58 180 255;

  --pp-dark-gray: var(--midnight);
  --pp-dark-gray-rgb: 52 52 52;

  --pp-light-gray: var(--off-white);
  --pp-light-gray-rgb: 220 225 228;

  --pp-font-main: var(--font-main);
}

.popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 8999;
  display: none;

  &.active {
    display: block;
  }

  &__close-bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: -1;
    transition: all 0.5s ease;
    animation-duration: 0.3s;
    animation-name: opacityPopupBg;
  }

  &__block {
    --pp-default-translate-x-from: -50%;
    --pp-default-translate-x-to: -50%;

    --pp-default-translate-y-from: -50%;
    --pp-default-translate-y-to: -50%;

    --pp-opacity-from: 0;
    --pp-opacity-to: 1;

    background: var(--pp-white);

    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 20px;

    width: 90%;
    max-width: ac(850px, 600px);
    padding: ac(40px, 30px) ac(40px, 24px);

    transform: translateY(var(--pp-default-translate-y-to))
      translateX(var(--pp-default-translate-x-to));
    transition: all 300ms ease-out;
    animation-duration: 0.6s;

    &.without-opacity {
      --pp-opacity-from: 1;
    }

    &.pos-l,
    &.pos-r {
      --pp-default-translate-x-from: 0;
      --pp-default-translate-x-to: 0;
    }

    &.pos-t,
    &.pos-b {
      --pp-default-translate-y-from: 0;
      --pp-default-translate-y-to: 0;
    }

    &.pos-t {
      top: 0;
      bottom: initial;
    }

    &.pos-r {
      right: 0;
      left: initial;
    }

    &.pos-b {
      top: initial;
      bottom: 0;
    }

    &.pos-l {
      right: initial;
      left: 0;
    }

    &.full-height {
      max-height: 100vh;
      height: 100%;
      right: 0;
      left: initial;
      --pp-default-translate-x-from: 0;
      --pp-default-translate-x-to: 0;

      .popup__content {
        max-height: calc(var(--vh, 1vh) * 72);
      }
    }

    &.slide-l {
      --pp-default-translate-x-from: 100%;
    }

    &.slide-r {
      --pp-default-translate-x-from: -100%;
    }

    &.slide-t {
      --pp-default-translate-y-from: -100%;
    }

    &.slide-b {
      --pp-default-translate-y-from: 100%;
    }

    &.slide-t,
    &.slide-r,
    &.slide-b,
    &.slide-l {
      animation-name: popupSlide;
    }
  }

  &__title {
    color: var(--midnight);
    font-size: ac(24px, 20px);
    line-height: 1.2;
    font-family: var(--pp-font-main);
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: ac(32px, 24px);
  }

  &__btn-close {
    position: absolute;
    right: ac(25px, 15px);
    top: ac(25px, 15px);
    transition: all 300ms ease-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .close-clip {
      transition: inherit;
      width: ac(24px, 20px);
      height: ac(24px, 20px);
      background: var(--pp-dark-blue);
      clip-path: polygon(
        20% 0%,
        0% 20%,
        30% 50%,
        0% 80%,
        20% 100%,
        50% 70%,
        80% 100%,
        100% 80%,
        70% 50%,
        100% 20%,
        80% 0%,
        50% 30%
      );
    }

    i {
      transition: inherit;
      font-size: ac(24px, 20px);
      color: var(--pp-dark-blue);
    }

    &:hover {
      .close-clip {
        background: var(--pp-blue);
        transform: scale(1.05);
      }

      i {
        color: var(--pp-blue);
        transform: scale(1.05);
      }
    }
  }

  .close-btn {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  &__content {
    max-height: calc(var(--vh, 1vh) * 66);

    .simplebar-track.simplebar-vertical {
      width: 4px;
      background: var(--pp-light-gray);
      border-radius: 4px;
      transform: translateX(10px);

      .simplebar-scrollbar {
        background: var(--pp-dark-blue);
        border-radius: 4px;

        &:before {
          content: none;
        }
      }
    }

    .popup-form {
      &__container {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: 15px;
        grid-row-gap: 15px;

        @mixin max-sm {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }
      }

      &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 18px;
        padding-top: ac(24px, 20px);
        flex-wrap: wrap;

        .popup-btn {
          margin-left: auto;
        }
      }
      .btn {
        box-shadow: none !important;
      }
    }

    .popup-btn {
      font-size: ac(16px, 14px);
      font-weight: 700;
      font-family: var(--pp-font-main);
      line-height: 1.2;
      text-transform: uppercase;
      background-color: var(--pp-dark-blue);
      color: var(--pp-white);
      border: none;
      outline: none;
      border-radius: 4px;
      padding: 12px 18px;
      cursor: pointer;
      transition: 0.3s ease-in-out all;
      position: relative;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: skew(45deg);
        pointer-events: none;
      }

      &:hover {
        &::after {
          background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 0.7) 100%
          );
          animation: gradientPopupBtn 0.3s linear forwards;
        }
      }

      &:active {
        transform: scale(0.9);
        opacity: 0.9;
      }
    }
  }
}

@keyframes popupSlide {
  from {
    transform: translateY(var(--pp-default-translate-y-from))
      translateX(var(--pp-default-translate-x-from));
    opacity: var(--pp-opacity-from);
  }

  to {
    transform: translateY(var(--pp-default-translate-y-to))
      translateX(var(--pp-default-translate-x-to));
    opacity: var(--pp-opacity-to);
  }
}

@keyframes opacityPopupBg {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes gradientPopupBtn {
  0% {
    transform: translateX(-100%) skew(45deg);
  }

  100% {
    transform: translateX(100%) skew(45deg);
  }
}

.popup__block_download {
  border-radius: 20px;
  box-shadow: 0px 7px 25px 0px #0000000f;
  max-width: 606px;
  max-height: calc(100vh - 40px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: ac(30px, 20px);

  .close-btn {
    position: absolute;
    right: ac(30px, 15px);
    top: ac(30px, 15px);
  }

  .popup {
    &__name {
      margin-bottom: 16px;
      font-size: ac(25px, 18px);
    }
    &__title {
      line-height: 1.26;
      font-size: ac(35px, 22px);
      font-weight: 400;
      text-transform: revert;
      margin-bottom: ac(27px, 20px);
    }
    &__description {
      font-size: ac(18px, 16px);
      line-height: 1.25;
      margin-bottom: 40px;
      font-family: var(--font-second);
      font-weight: 300;
    }
    &__content {
      max-height: calc(var(--vh, 1vh) * 80);
    }
  }
  .download-popup-form {
    display: flex;
    gap: 20px;
    flex-direction: column;
    .input-wr {
      input {
        padding-left: ac(48px, 20px);
      }
    }
    &-footer {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: flex-start;
      padding-top: 10px;
      width: 100%;

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.26;

        a {
          display: inline-block;
          text-decoration: underline;
          transition: 0.3s ease;

          &:hover {
            color: var(--sky-blue);
          }
        }
      }
      .btn {
        align-self: flex-end;
        margin-bottom: 15px;
      }
    }
  }
}

.ext-form-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: none;

  &-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--midnight);
    opacity: 0.6;
  }

  &-container {
    width: 900px;
    max-width: calc(100% - 30px);
    max-height: calc(100vh - 40px);
    background: var(--white);
    box-shadow: 0 7px 25px 0 rgba(0, 0, 0, 0.06);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    border-radius: 20px;
    overflow: hidden;
    padding: 50px ac(50px, 20px) 15px;
    display: flex;
    animation: fadeDown forwards 0.3s;

    .close-btn {
      position: absolute;
      right: ac(20px, 10px);
      top: ac(20px, 10px);
      z-index: 2;
    }

    &-inner {
      max-height: 100%;
      width: 100%;
    }
  }
  .hs_submit {
    margin-bottom: 15px;
  }
}
