/* ----------------------- Common desktop header ----------------------- */
.header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: ac(26px, 12px) 0;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;

  &.absolute {
    position: absolute;
  }

  &.fixed {
    position: fixed;
  }

  &:before {
    position: absolute;
    inset: 0;
    content: '';
    z-index: -1;
    background-color: transparent;
    opacity: 0;
    transition: all 0.25s ease;
  }

  &.scrolled {
    &:not(.open-menu) {
      -webkit-backdrop-filter: blur(16px);
      backdrop-filter: blur(16px);
      padding: ac(15px, 10px) 0;

      &:before {
        opacity: 0.6;
        background-color: var(--black);
      }

      .logo {
        transform: scale(0.8);
        &:hover {
          transform: scale(0.85);
        }

        @mixin max-xs {
          width: 165px;
        }
      }
    }
  }

  .logo {
    height: auto;
    width: ac(200px, 192px);
    flex-shrink: 0;
    transition: transform 0.25s ease, width 0.25s ease;
    z-index: 100;
    margin-right: auto;

    &:hover {
      transform: scale(1.06);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: left;
      &:nth-of-type(1) {
        display: block;
      }
      &:nth-of-type(2) {
        display: none;
      }
    }

    @mixin max-xs {
      width: 180px;
    }
  }

  .navbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .burger {
    display: none;
  }

  .navbar-nav {
    display: flex;
    flex-grow: 1;
    [data-simplebar] {
      width: 100%;
    }

    &-buttons {
      display: flex;
      gap: 10px;
      .btn {
        min-width: 0;
      }
    }
  }

  .menu-wr {
    flex: 1;
    display: flex;
    align-items: center;

    .simplebar-content-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
    }

    @mixin mobile-menu-end-point {
      max-height: 41px;
      margin-top: auto;
      margin-bottom: auto;

      .simplebar-content-wrapper {
        overflow: visible !important;
        max-height: 41px !important;
      }

      .simplebar-mask {
        overflow: visible !important;
        max-height: 41px !important;
      }

      .simplebar-wrapper {
        overflow: visible !important;
        max-height: 41px !important;
      }

      .simplebar-track {
        display: none !important;
        max-height: 41px !important;
      }
    }
  }

  .menu {
    width: 100%;
    display: flex;
    gap: ac(25.7px, 10px, 1200);
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    padding: 0 ac(27px, 15px, 1200);
  }

  .menu-item {
    position: relative;

    &.menu-dropdown {
      @mixin mobile-menu-end-point {
        padding-right: 14px !important;
      }

      position: relative;

      @mixin mobile-menu-start-point {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;

        .menu-dropdown__list {
          width: 100%;
        }
      }

      &.active {
        @media (max-width: 1280px) {
          .menu-dropdown__arrow {
            transform: translateY(0) scaleY(-1) !important;
          }
        }
      }

      &:hover {
        @media (min-width: 1281px) {
          .menu-dropdown {
            &__arrow {
              transform: translateY(-50%) scaleY(-1);
            }

            &__list {
              max-height: 500px;
              opacity: 1;

              .menu-link {
                color: var(--white) !important;

                &::before {
                  display: none;
                }

                &:hover {
                  color: var(--sky-blue) !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .menu-dropdown {
    &__arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--white);
      cursor: pointer;
      transition: all 0.3s ease;

      @mixin mobile-menu-end-point {
        position: absolute;
        top: 55%;
        transform: translateY(-50%) scale(1, 1);
        right: 0;
        font-size: 5px;
      }

      @mixin mobile-menu-start-point {
        transform: scale(1, 1);
        font-size: 8px !important;
        margin-left: 6px;
      }
    }

    &__list {
      transition: max-height 0.3s ease, opacity 0.3s ease, margin-top 0.3s ease;
      opacity: 0;
      max-height: 0;
      overflow: hidden;
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      /*overflow-y: auto;
            scrollbar-width: thin;*/

      &::-webkit-scrollbar-button {
        display: none;
        background: red;
      }

      &::-webkit-scrollbar-button {
        height: 0;
        width: 0;
        opacity: 0;
      }

      &::-webkit-scrollbar {
        width: 2px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 0;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--sky-blue);
        border-radius: 0;
      }

      @mixin mobile-menu-end-point {
        position: absolute;
        bottom: -2px;
        transform: translateY(100%);
        padding: 2px 14px;
        background: var(--midnight);
        border-radius: 5px;

        z-index: 999;

        .menu-link {
          white-space: nowrap;
        }
      }

      /*.menu__link {
              white-space: nowrap;
            }*/

      @media (max-width: 639px) {
        align-items: center;
      }

      @mixin mobile-menu-start-point {
        align-items: center;

        &.active {
          max-height: 800px;
          opacity: 1;
          margin-top: 25px;
          grid-row-gap: 25px;
        }
      }
    }
  }

  .menu-additional-content {
    display: none;

    @mixin mobile-menu-start-point {
      /*transform: translateY(-8px);*/
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      margin-top: auto;

      .btn {
        width: 100%;
      }
    }
  }

  &:not(.header-mobile) {
    .menu-item {
      @mixin mobile-menu-end-point {
        padding: 5px 0;
      }

      &:hover {
        @mixin mobile-menu-end-point {
          .dropdown-menu {
            display: block;
          }
        }
      }
    }
  }

  .menu-link {
    position: relative;
    font-size: 16px;
    align-self: 1.25;
    font-family: var(--font-main);
    line-height: normal;
    font-weight: 400;
    color: var(--off-white);
    transition: all 0.3s ease;
    cursor: pointer;
    padding: 5px 0;

    &:hover {
      color: var(--sky-blue);
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    padding: 10px 10px 10px 15px;
    border-radius: 10px;
    background: var(--cl-grey);
    box-sizing: content-box;
    display: none;

    li {
      .menu-link {
        padding: 5px 0;
        text-transform: capitalize;
      }
    }
  }

  .navbar-nav-footer {
    display: flex;
    flex-direction: column;

    .btn {
      min-height: 48px;
      border-radius: 27px;
    }
  }

  .socials-buttons {
    display: none;
    gap: 12px;
  }
}

/* end of Common desktop header */

/* ----------------------- Common desktop header Mobile HEADER ----------------------- */
.burger,
.menu-toggle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 1px solid var(--white);
  padding: 17px 14px;
  cursor: pointer;
  transition: transform 0.3s;

  div {
    height: 2px;
    background: var(--sky-blue);
    width: 26px;
    transition: transform 0.3s, opacity 0.3s;
    border-radius: 5px;

    &:nth-of-type(1) {
      transform-origin: left top;
    }

    &:nth-of-type(2) {
      transform-origin: left center;
    }

    &:nth-of-type(3) {
      transform-origin: left bottom;
    }
  }

  &:hover {
    transform: scale(1.1);
  }

  &.active {
    div {
      &:nth-of-type(1) {
        transform: rotate(45deg) translate(12%, -161%);
      }

      &:nth-of-type(2) {
        transform: translateX(-100%);
        opacity: 0;
      }

      &:nth-of-type(3) {
        transform: rotate(-45deg) translate(12%, 161%);
      }
    }
  }
}

@mixin mobile-menu-end-point {
  .header {
    .menu-link {
      transition-delay: 0ms !important;
    }
    &.header-dark {
      &:not(.scrolled) {
        .logo {
          img {
            &:nth-of-type(1) {
              display: none;
            }
            &:nth-of-type(2) {
              display: block;
            }
          }
        }

        .menu-link {
          color: var(--midnight);
          &::before {
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            bottom: 0;
            height: 1px;
            background: var(--midnight);
            width: 0;
            transition: 0.3s;
            transform: translate(-50%);
          }
          &:hover {
            &::before {
              width: 100%;
            }
          }
        }

        .menu-dropdown__arrow {
          color: var(--midnight) !important;
        }

        .navbar-nav-buttons .btn {
          color: var(--midnight);
          border-color: var(--midnight);
          &:hover {
            color: var(--off-white);
            border-color: var(--off-white);
          }
        }
      }
      &.scrolled {
        .logo {
          img {
            &:nth-of-type(1) {
              display: block !important;
            }
            &:nth-of-type(1) {
              display: none;
            }
          }
        }
      }
    }
  }
}

@mixin mobile-menu-start-point {
  .header {
    .burger,
    .menu-toggle {
      display: flex;
    }
    .socials-buttons {
      display: flex;
    }

    &.header-dark {
      &::before {
        background: var(--midnight);
        opacity: 0.7;
      }
      & + .header-close-wrapper {
        background: rgba(0, 0, 0, 0.9);
      }
      .logo {
      }
      &.scrolled {
        .logo {
          img {
            &:nth-of-type(1) {
              display: block;
            }
            &:nth-of-type(2) {
              display: none;
            }
          }
        }
      }
      &.menu-open {
        .logo {
          img {
            &:nth-of-type(1) {
              display: block;
            }
            &:nth-of-type(2) {
              display: none;
            }
          }
        }
      }
    }

    .navbar-nav {
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;
      height: calc(var(--vh, 1vh) * 100);
      min-width: 320px;
      padding: 132px 24px 80px;
      transition: transform 0.3s ease-out;
      overflow: hidden;
      flex-direction: column;
      align-items: center;
      pointer-events: none;

      @media screen and (max-height: 730px) {
        padding-top: 110px;
        padding-bottom: 40px;
      }
      &.active {
        .menu-item,
        .navbar-nav-footer {
          opacity: 1;
          transform: none;
        }
      }

      &.nav-slide-left {
        right: auto;
        left: 0;
        transform: translateX(-100%);

        &.active {
          transform: translateX(0);
        }
      }

      &.nav-slide-right {
        right: 0;
        left: auto;
        transform: translateX(100%);

        &.active {
          transform: translateX(0);
        }
      }

      &.nav-slide-down {
        right: auto;
        left: 0;
        transform: translateY(-100%);

        &.active {
          transform: translateY(0);
        }
      }

      &.nav-slide-up {
        right: auto;
        left: 0;
        transform: translateY(100%);

        &.active {
          transform: translateY(0);
        }
      }

      .menu-wr {
        width: 100%;
        pointer-events: all;
        min-height: 0;
        flex-grow: 1;
        display: flex;
        padding-bottom: 20px;
        .simplebar-content-wrapper {
          align-items: flex-start;
          justify-content: stretch;
        }
        [data-simplebar] {
          width: 100%;
          align-self: center;
          max-height: 100%;
          margin: 10px 0;
        }

        .simplebar-content-wrapper {
          justify-content: center;
        }
      }

      .menu {
        width: 100%;
        max-height: 100%;
        min-height: 100%;
        flex-direction: column;
        justify-content: flex-start;
        padding: 0 0 2px;
        align-items: center;
        align-self: center;
        position: relative;
        z-index: 2;
        overflow-x: hidden;
        overflow-y: auto;
        gap: 25px;
      }

      .menu-link {
        padding: 0;
        margin: 0;
        font-size: 22px;
        line-height: 1.27;
        text-align: center;
      }

      .menu-item {
        margin: 0;
        padding: 0;
        transform: scale(0.5);
        opacity: 0;
        transition-duration: 0.3s;
      }

      &-footer {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 48px;
        align-items: center;
        pointer-events: all;
        transform: translateY(20px);
        opacity: 0;
        transition: 0.3s 0.5s;
      }

      &-buttons {
        flex-direction: column;
        align-items: center;
        gap: 16px;
        width: 100%;

        .btn {
          width: 100%;
          max-width: 327px;
        }
      }
    }

    .dropdown-menu {
      position: relative;
      top: 0;
    }
  }

  @media (max-height: 750px) {
    .header .navbar-nav {
      padding-top: 78px;
    }
  }
  @media (max-height: 650px) {
    .header .navbar-nav {
      padding-bottom: 20px;
      .menu {
        gap: 10px;
      }
      .menu-wr [data-simplebar] {
        max-height: 260px;
      }
      &-footer {
        gap: 10px;
        .socials-buttons {
          margin-bottom: 10px;
        }
      }
      &-buttons {
        gap: 10px;
      }
    }
  }
  @media (max-height: 550px) {
    .header .navbar-nav .menu-wr [data-simplebar] {
      max-height: 220px;
    }
  }
  @media (max-height: 465px) {
    .header .socials-buttons {
      display: none;
    }
  }
  @media (max-height: 440px) {
    .header .navbar-nav .menu-wr [data-simplebar] {
      max-height: 110px;
    }
  }
}

/* ----------------------- HEADER COMPONENTS ----------------------- */

.header-close-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 77;
  background: rgba(0, 0, 0, 0.7);
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;

  &.active {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    cursor: pointer;
  }
}

/* end of HEADER COMPONENTS */
