@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta Sans:wght@300;400;500;600;700;800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700&display=swap');



:root {
  --off-white: #F5F5F5;
  --black: #040D1D;
  --midnight: #040D1D;
  --deep-blue: #011472;
  --sky-blue: #49A6E8;
  --ice-blue: #A8D7FA;
  --white: #FFFFFF;
  --red: red;
  --fz: 18px;
  --line-height: 1.25;


  --font-main: 'Plus Jakarta Sans', sans-serif;

  --font-second: 'Lexend', sans-serif;

  /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */

  --mobile-menu-start-point: 1280px;

  --vh: calc(100vh / 100);
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
/* @custom-media --mobile-menu-start-point (max-width: 1200px); */

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
/* @custom-media --mobile-menu-end-point (min-width: 1201px); */

@define-mixin mobile-menu-start-point {
  @media (max-width: 1280px) {
    @mixin-content;
  }
}

@define-mixin mobile-menu-end-point {
  @media (min-width: 1281px) {
    @mixin-content;
  }
}
