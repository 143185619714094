.expertise-page{
    .hero-section{
        .section-bg{
            &-img{
                opacity: 0.4;
                mix-blend-mode: screen;
            }
        }
    }
    .industry-section{
        padding-bottom: ac(112px, 50px);
    }
    .client-ts-section{
        padding: ac(173px, 80px) 0 ac(68px, 30px);
    }
    .news-section{
        .title-wr{
            padding-bottom: ac(60px, 35px);
        }
        .bg-layer{
            top: 201px;
            left: 66%;
            transform: translate(-50%);
        }
    }
}

.mission-section{
    padding: ac(110px, 50px) 0 ac(140px, 50px);
    position: relative;
    .title{
        margin-bottom: 50px;
    }
    &-description{
        margin-bottom: ac(50px, 35px);
        font-family: var(--font-second);
        font-weight: 300;
        font-size: ac(18px, 16px);
        line-height: 1.25;
        max-width: 924px;

        p:not(:last-of-type){
            margin-bottom: 15px;
        }
    }
    .btn-wr{
        display: flex;
        gap: ac(30px, 25px);
        flex-wrap: wrap;
    }
    .bg-layer{
        top: 80px;
        left: 0;
        transform: translate(-12.6%);
    }
}