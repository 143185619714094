.hbspt-form {
  form {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;
      &>div:not([class]){
          grid-column: 1/-1;
      }

    .field {
      display: flex;
      flex-direction: column;
      gap: 14px;

      input {
        width: 100%;
      }
    }
  }
}

.hs-company {
  grid-column: 1/-1;
}

.legal-consent-container {
  grid-column: 1/-1;
  padding-top: 20px;
}

.hs-form-booleancheckbox {

    label{
        display: flex;
        align-items: center;
        width: fit-content;
        cursor: pointer;
        transition: 0.3s;
        &:hover{
            color: var(--sky-blue);
        }
        span{
            margin-left: 10px!important;
        }
    }
  input {
    width: 15px !important;
    height: 15px !important;
      min-height: auto;
  }
}
.hs-submit{
    grid-column: 1/-1;
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
    .actions{
        display: inline-flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        gap: 15px;
        min-height: ac(67px, 56px);
        border-radius: 67px;
        border: 1px solid var(--off-white);
        font-size: 16px;
        transition: 0.3s;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0);
        width: fit-content;
        max-width: 100%;
        flex-shrink: 0;
        min-width: ac(258px, 150px);
        cursor: pointer;
        border-color: var(--black);
        background: var(--black);
        color: var(--off-white);
        padding: 10px 16px;
        input{
            color: inherit;
            height: auto;
            min-height: auto;
            padding: 0;
            margin: 0;
            cursor: pointer;
        }

        &:hover {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
            transform: translateY(-2px);
            background: var(--sky-blue);
            border-color: var(--sky-blue);
        }
        &::after{
            content: '';
            display: flex;
            width: 27px;
            height: 27px;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: var(--off-white);
            background-image: url("data:image/svg+xml,%3Csvg width='13' height='11' viewBox='0 0 13 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.2863 6.14277L0.714844 6.14277L0.714844 5.14277L12.2863 5.14277V6.14277Z' fill='%23040D1D'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.9912 5.49992L8.16876 10.3524L7.45947 9.64746L11.5813 5.49992L7.45947 1.35237L8.16876 0.647461L12.9912 5.49992Z' fill='%23040D1D'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: center center;
            color: var(--black);
            font-size: 10px;
            transition: 0.3s;
            cursor: pointer;
            flex-shrink: 0;
        }

    }
}

.hs-richtext, .hs-dependent-field{
    font-size: 14px;
    line-height: 1.26;
}
.hs-richtext{
    h1{
        font-size: ac(45px, 32px);
        line-height: 1.26;
    }
}
.hs-dependent-field{
    margin-top: 10px;
}
.hs-error-msg{
    color: var(--sky-blue);
    font-size: ac(18px, 16px);
}
.submitted-message{
    font-size: ac(30px, 22px);
    display: block;
    margin-bottom: ac(17px, 12px);
    text-align: center;
}

@media (max-width: 650px) {
    .hbspt-form form{
        grid-template-columns: 1fr;
    }
}
