.job-details-page {
  .hero-section {
    padding-bottom: 50px;
    .title {
      width: 100%;
      max-width: 818px;
      margin-bottom: ac(52px, 40px);
      font-size: ac(90px, 40px, 375);
    }

    .btn {
      margin-bottom: 50px;
    }

    .bg-layer.aos-animate {
      transform: translate(37.7%, -42.8%);
    }
  }
}

.hero-caption-info {
  display: flex;
  align-items: flex-start;
  gap: 30px;
  box-shadow: 0px 7px 25px 0px #0000000f;
  padding: 11px;
  border-radius: 20px;
  padding-left: 30px;
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 30px;
    padding: 0;
    margin-bottom: 15px;
    li {
      min-width: 0;

      &:nth-of-type(1) {
        max-width: 400px;
      }
    }
  }

  &-label {
    color: var(--ice-blue);
    font-size: ac(17px, 16px);
    display: block;
    margin-bottom: 10px;
    padding-top: 20px;
  }

  &-text {
    font-size: ac(25px, 18px);
    line-height: 1.26;
    font-weight: 400;
  }

  &-card {
    border-radius: 20px;
    background: var(--off-white);
    padding: 15px 10px;
    flex-shrink: 0;
    min-width: 288px;
    display: flex;
    gap: 10px;
    margin-left: auto;
    flex-shrink: 0;
    align-self: center;
    &-thumbnail {
      margin-top: 5px;
      position: relative;
      overflow: hidden;
      flex-shrink: 0;
      width: ac(60px, 50px);
      align-self: flex-start;
      border-radius: 50%;

      &::before {
        content: '';
        display: block;
        padding-top: 100%;
        border-radius: 20px;
      }
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s;
      }
    }

    &-text {
      flex-shrink: 0;
      min-width: 0;
      margin-bottom: 5px;
    }

    &-name {
      font-size: ac(25px, 18px);
      font-weight: 400;
      line-height: 1.26;
      margin-bottom: 10px;
      display: block;
    }

    &-position {
      font-size: ac(17px, 16px);
      line-height: 1.26;
      font-weight: 400;
      margin-bottom: 15px;
    }

    .btn-social {
      width: 20px;
      height: 20px;
      font-size: 7.2px;
    }
  }
}

.job-article {
  padding: 0 0 ac(110px, 70px);

  .bg-layer {
    bottom: 0;
    left: 0;
    transform: translate(-12.3%, 5.7%);
  }

  .content-element {
    font-size: ac(18px, 16px);
    line-height: 1.27;
    font-family: var(--font-second);
    font-weight: 300;

    &:not(.privacy-article) {
      width: 924px;
      max-width: 100%;
    }

    h2 {
      font-size: ac(35px, 22px, 320);
      line-height: 1.26;
      margin-bottom: ac(27px, 20px);
      margin-top: ac(50px, 35px);
      font-family: var(--font-main);
      word-break: break-word;
    }

    h3 {
      font-size: ac(30px, 20px, 320);
      line-height: 1.26;
      margin-bottom: ac(24px, 20px);
      margin-top: ac(40px, 35px);
      font-family: var(--font-main);
      word-break: break-word;
    }

    h4 {
      font-size: ac(26px, 18px, 320);
      line-height: 1.26;
      margin-bottom: ac(24px, 20px);
      margin-top: ac(30px, 35px);
      font-family: var(--font-main);
      word-break: break-word;
    }

    p {
      margin-bottom: 15px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    ul {
      padding-left: 0;
      list-style: none inside;
      display: flex;
      flex-direction: column;
      gap: 15px;

      li {
        padding-left: ac(27px, 20px);
        position: relative;
        &::before {
          content: '';
          display: block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: var(--midnight);
          position: absolute;
          left: ac(12px, 4px);
          top: ac(9px, 7px);
          transform: translate(-50%);
        }
      }
    }
  }

  &-footer {
    margin-top: 50px;

    .btn {
      margin-top: 63px;
    }
  }
}

@media (max-width: 750px) {
  .hero-caption-info {
    flex-direction: column;
    align-items: center;
    ul {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    &-card {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 15px;
    }
  }
  .hero-caption-info-card {
    min-width: 0;
    max-width: 100%;
    width: fit-content;
    padding: 20px 15px;
    justify-content: center;
  }
}
