.hero-section.hero-section-contact{
  padding-top: 174px;
  padding-bottom: ac(70px, 35px);
  .bg-layer{
    width: perc(1060);
    max-width: 1060px;
    &.aos-animate{
      transform: translate(37.6%, -42.8%);
    }
  }
  .title{
    margin-bottom: ac(81px, 40px);
  }
}

.contact-form {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: ac(30px, 20px);

  &-footer {
    grid-column: 1/-1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 30px;
    font-size: 14px;
    line-height: 1.25;
    text-align: right;

    a {
      display: inline;
      transition: 0.3s;

      &:hover {
        color: var(--sky-blue);
      }
    }
  }
}

.contact-offices-section{
  padding: ac(70px, 35px) 0 ac(110px, 50px);
  .bg-layer{
    left: 0;
    top: 0;
    transform: translate(-12.3%, -20.8%);
  }
  .title{
    margin-bottom: ac(60px, 35px);
  }
}

.contact-offices-swiper-wr{
  .swiper{
    overflow: visible;
    &-slide{
      display: flex;
      height: auto;
    }
    &-button{
      margin-top: 35px;
    }
  }
}

.contact-offices-card{
  padding: ac(40px, 20px);
  background: var(--sky-blue);
  border-radius: 20px;
  width: 100%;
  flex-grow: 1;
  &-title{
    font-size: ac(40px, 25px);
    line-height: 1.26;
    font-weight: 600;
    margin-bottom: ac(30px, 20px);
  }
  ul{
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin-bottom: 30px;
  }
  a{
    display: inline;
    font-size: ac(18px, 16px);
    line-height: 1.33;
    font-weight: 300;
    font-family: var(--font-second);
    transition: 0.3s;
    &:hover{
      color: var(--white);
    }
  }
}

@media (max-width: 992px) {
  .contact-form{
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
}

@media (max-width: 374px) {
  .contact-form-footer{
    font-size: 15px;
    text-align: left;
  }
}