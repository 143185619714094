.news-page {
  .hero-section {
    padding-bottom: 31px;

    .title {
      max-width: 572px;
      margin-bottom: 80px;
    }

    .job-search-form {
      max-width: 873px;
    }
    .search-wr {
      min-height: 100%;
      input {
        min-height: 100%;
        &::placeholder {
          font-size: ac(22px, 14px, 320);
        }
      }
    }
  }
}

.news-results {
  padding-bottom: ac(111px, 80px);

  .bg-layer {
    bottom: 110px;
    left: 0;
    transform: translate(-13%, 0.5%);
  }

  &-wrapper {
    --gap: ac(15px, 10px);
    display: flex;
    gap: ac(30px, 10px) var(--gap);
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &-footer {
    display: flex;
    justify-content: center;

    .btn-link {
      margin-top: 60px;
    }
  }
}

.news-card-blogs {
  &.news-card {
    flex-shrink: 0;
    min-height: ac(285px, 220px);

    .news-card-header {
      font-size: ac(18px, 16px);
      gap: 10px;
    }

    .news-card-title {
      font-size: ac(35px, 20px);
      @mixin max-line-length 4;
    }

    &:nth-child(11n + 1) {
      width: calc(50% - var(--gap));
    }

    &:nth-child(11n + 2) {
      width: calc(24.4% - var(--gap));

      .news-card {
        &-title {
          font-size: ac(25px, 20px);
        }
      }
    }

    &:nth-child(11n + 3) {
      width: calc(24.4% - var(--gap));

      .news-card {
        &-title {
          font-size: ac(25px, 20px);
        }
      }
    }

    &:nth-child(11n + 4) {
      width: calc(24.4% - var(--gap));

      .news-card {
        &-title {
          font-size: ac(25px, 20px);
          line-height: 1.26;
        }
      }
    }

    &:nth-child(11n + 5) {
      width: calc(50% - var(--gap));
    }

    &:nth-child(11n + 6) {
      width: calc(24.4% - var(--gap));

      .news-card {
        &-title {
          font-size: ac(25px, 20px);
          line-height: 1.26;
        }
      }
    }

    &:nth-child(11n + 7) {
      width: calc(33% - var(--gap));
    }

    &:nth-child(11n + 8) {
      width: calc(33% - var(--gap));
    }

    &:nth-child(11n + 9) {
      width: calc(33% - var(--gap));
    }

    &:nth-child(11n + 10) {
      width: calc(50% - var(--gap));
    }

    &:nth-child(11n + 11) {
      width: calc(50% - var(--gap));
    }

    &.news-card-50 {
      @media (min-width: 1201px) {
        width: calc(50% - var(--gap));

        .news-card {
          &-title {
            font-size: ac(35px, 20px);
            line-height: 1.27;
          }
        }
      }
    }

    .circle-btn {
      position: relative;
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        bottom: 5px;
        width: 0;
        height: 1px;
        background: var(--midnight);
        transition: 0.3s;
        transform: translate(-50%);
      }
    }
  }
  &.news-card-download {
    .btn-link {
      color: var(--white);
      font-size: 13px;
      line-height: 1.029;
      gap: ac(8px, 5px);
      transform: translateY(5%);
      .circle-btn {
        font-size: 16px;
      }
    }
  }
  &:hover {
    transform: none;
    color: var(--midnight);

    .news-card-thumbnail {
      background: var(--sky-blue);

      &::before {
        display: none;
      }

      img {
        opacity: 0 !important;
      }
    }
  }
}

@media (max-width: 1200px) {
  .news-results-wrapper {
    justify-content: center;
    .news-card-blogs.news-card.news-card {
      width: calc(33.3% - var(--gap));
    }
  }
}

@media (max-width: 992px) {
  .news-page {
    .job-search-form {
      .search-wr {
        min-height: revert;
        input {
          min-height: ac(67px, 57px);
        }
      }
    }
  }
  .news-results-wrapper {
    .news-card-blogs.news-card.news-card {
      width: calc(50% - var(--gap));
    }
  }
}

@media (max-width: 750px) {
  .news-page .hero-section .job-search-form {
    flex-direction: column;
    .input-wr {
      min-height: 67px;
    }
    .simple-select {
      width: 100%;
      max-width: 100%;
    }
    .circle-btn {
      margin-left: auto;
    }
  }
}

@media (max-width: 600px) {
  .news-results-wrapper {
    .news-card-blogs.news-card.news-card {
      width: 100%;
    }
  }
}
