.btn {
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 15px;
  min-height: ac(67px, 56px);
  border-radius: 67px;
  border: 1px solid var(--off-white);
  color: var(--off-white);
  font-size: 16px;
  padding: 10px 21px;
  transition: 0.3s;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0);
  width: fit-content;
  max-width: 100%;
  flex-shrink: 0;
  min-width: ac(258px, 150px);
  cursor: pointer;

  &:hover {
    background: var(--sky-blue);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
    transform: translateY(-2px);
    border-color: var(--sky-blue);
  }

  &.btn-black {
    border-color: var(--black);
    background: var(--black);
    color: var(--off-white);

    padding: 10px 16px;

    &:hover {
      background: var(--deep-blue);
      border-color: var(--deep-blue);
    }
  }

  &.btn-white {
    border-color: var(--off-white);
    background: var(--off-white);
    color: var(--black);
    padding: 10px 16px;

    .circle-btn {
      background: var(--black);
      color: var(--off-white);
    }

    &:hover {
      background: var(--sky-blue);
      border-color: var(--sky-blue);
      color: var(--off-white);
    }
  }

  &.btn-big {
    min-width: 258px;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
}

.btn-link {
  display: flex;
  align-items: center;
  transform: translate(0);
  position: relative;
  width: fit-content;
  transition: 0.3s;
  font-size: 20px;
  line-height: 1.25;
  gap: 15px;
  cursor: pointer;

  .circle-btn {
    transition: transform 0.3s;
    will-change: transform;
  }

  &:hover {
    .circle-btn {
      transform: scale(1.2);
    }
  }
}

.circle-btn {
  display: flex;
  width: 27px;
  height: 27px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--off-white);
  color: var(--black);
  font-size: 10px;
  transition: 0.3s;
  cursor: pointer;
  flex-shrink: 0;

  &.circle-btn-black {
    background: var(--black);
    color: var(--off-white);

    &.circle-btn-big {
      border: 1px solid var(--black);

      &:hover {
        background-color: #f5f5f5 !important;
        color: var(--black);
      }
    }
  }

  &.circle-btn-big {
    width: 67px;
    height: 67px;
    font-size: 23px;

    &.circle-btn-black {
      &:hover {
        background: var(--sky-blue);
      }
    }
  }
}

.socials-buttons {
  display: flex;
  gap: 20px;
}

.btn-social {
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid var(--off-white);
  justify-content: center;
  align-items: center;
  color: var(--off-white);
  transition: background-color 0.3s;
  cursor: pointer;
  line-height: 1;

  &:hover {
    background: var(--sky-blue);
  }

  i {
    pointer-events: none;
  }

  &.btn-social-white {
    background: var(--white);
    color: var(--midnight);
    border: 0;

    &:hover {
      background: var(--sky-blue);
      color: var(--off-white);
    }
  }

  &.btn-social-blue {
    background: var(--sky-blue);
    color: var(--white);
    border: 0;

    &:hover {
      background: var(--ice-blue);
      color: var(--midnight);
    }
  }

  &.btn-social-border-black {
    border-color: var(--midnight);
    color: var(--midnight);

    &:hover {
      background: var(--sky-blue);
      color: var(--white);
      border-color: var(--sky-blue);
    }
  }
}

.swiper-buttons {
  width: fit-content;
  display: flex;
  gap: ac(20px, 12px);
  position: relative;
  left: auto;
  top: auto;
  bottom: auto;
  margin: 0 auto;

  .swiper-button {
    font-size: ac(23px, 19px);
    position: relative;
    transform: none;
    margin: 0;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    display: flex;
    width: ac(67px, 56px);
    height: ac(67px, 56px);
    justify-content: center;
    align-items: center;
    color: var(--black);
    border-radius: 50%;
    transition: 0.3s;
    background: var(--ice-blue);
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0);

    &::after {
      display: none;
    }

    &::before {
      content: '\e900';
      display: block;
      font-family: 'icomoon' !important;
    }

    &.swiper-button-prev {
      &::before {
        content: '\e901';
      }
    }

    &:hover {
      transform: translateY(-1px);
      background: var(--sky-blue);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
      color: var(--white);
    }

    &.swiper-button-black {
      background: var(--black);
      color: var(--white);
      border: 1px solid var(--ice-blue);

      &:hover {
        background: var(--ice-blue);
        color: var(--black);
      }
    }

    &.swiper-button-lock {
      display: none;
    }
  }
}

.close-btn {
  color: var(--white);
  background: var(--midnight);
  border-radius: 50%;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 27px;
  height: 27px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: var(--sky-blue);
  }
}

.toggle-btn {
  display: block;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background: var(--white);
  position: relative;
  cursor: pointer;
  transition: 0.3s;
  flex-shrink: 0;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    background: var(--midnight);
    width: 13px;
    height: 1px;
    transition: 0.3s;
  }

  &::after {
    width: 1px;
    height: 13px;
  }

  &.active {
    background: var(--sky-blue);

    &::after {
      height: 0;
    }
  }

  &:hover {
    background: var(--ice-blue);
  }
}

.search-btn {
  display: flex;
  width: 49px;
  height: 49px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--black);
  color: var(--off-white);
  position: absolute;
  left: ac(9px, 4px);
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  font-size: 22px;
  &:hover {
    background-color: var(--sky-blue);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
}

.simple-select-filter-bth {
  background: var(--ice-blue);
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 17px;
  gap: 6px;
  font-size: 14px;
  i {
    font-size: 8px;
    cursor: pointer;
    transition: 0.3s;
    line-height: 1;
    &:hover {
      color: var(--white);
    }
  }
}
