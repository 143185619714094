.job-search-page {
  .hero-section {
    padding-bottom: ac(60px, 40px);

    .title {
      margin-bottom: ac(81px, 40px);
    }
  }
}

.job-search-form {
  display: flex;
  gap: 8px;
  max-width: 1181px;

  .input-wr {
    flex-grow: 1;
    display: flex;
    min-width: 0;
    flex-direction: row;

    input {
      height: auto;
    }
  }

  .simple-select {
    display: flex;
    height: auto;

    &-item {
      width: 100%;
      height: auto;
    }

    @media (max-width: 992px) {
      height: 57px;
    }
  }
  .search-buttons {
    .btn {
      display: none;
    }
  }

  .circle-btn.circle-btn-big.circle-btn-black {
    border: 1px solid var(--midnight);
  }

  .circle-btn.circle-btn-big.circle-btn-black:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
}

.search-filters-wrapper {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  margin-bottom: 30px;

  .simple-select-wrapper {
    width: 188px;
    border: 1px solid var(--midnight);
    padding: 5px 16px 5px 17px;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 28px;
    position: relative;
    font-family: var(--font-main);
    flex-shrink: 0;
    height: 38px;

    & > span {
      font-size: 14px;
      flex-shrink: 0;
    }
  }

  .simple-select {
    font-size: 14px;
    flex: 1;
    min-width: 0;
    width: auto;
    position: static;
    font-family: var(--font-main);
    font-weight: 600;
    padding: 0;
    max-height: 100%;

    &-dropdown {
      width: 100%;
    }

    &-item {
      height: auto;
      background: transparent;
      width: 100%;
      padding: 0;
      max-width: 100%;
      gap: 5px;
      border-radius: 0;
      max-height: 100%;

      span {
        @mixin max-line-length 1;
      }
    }

    &-toggle {
      width: auto;
      border: 0;
      height: auto;

      i {
        font-size: 5px;
      }
    }

    &-list {
      &-item {
        padding: 10px 17px;
      }
    }
  }
}

.search-filters {
  display: flex;
  gap: 15px 8px;
  align-items: center;
  flex-wrap: wrap;

  .filter-bth {
    display: none;

    &.active {
      display: flex;
    }
  }
}

.job-results-section {
  padding-bottom: ac(141px, 60px);

  .btn-link {
    margin-top: 61px;
  }
}

.job-results-wrapper {
}

.job-results-grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  place-items: center;
  margin: 0 auto;
  gap: ac(30px, 20px, 1200);

  .role-card {
    box-shadow: 0px 7px 25px 0px #0000000f;
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .job-results-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (max-width: 992px) {
  .job-search-form {
    flex-direction: column;
    .simple-select {
      width: 100%;
    }
    .search-buttons {
      width: 100%;
      .btn {
        display: inline-flex;
        width: 100%;
      }
    }

    .circle-btn.circle-btn-big {
      display: none;
    }
  }
}

@media (max-width: 750px) {
  .job-results-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 550px) {
  .job-results-grid {
    grid-template-columns: 1fr;
  }

  .search-filters-wrapper {
    flex-direction: column;
  }
}
