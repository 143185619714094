.power-section.power-section-about {
  padding: ac(110px, 45px) 0 ac(86px, 40px);

  .title {
    margin-bottom: ac(63px, 35px);
  }

  .cont {
    gap: ac(45, 40px);
  }

  .power-section-text {
    max-width: 100%;
  }
}

.approach-section {
  background: var(--deep-blue);
  padding: ac(52px, 40px) 0 ac(87px, 40px);
  color: var(--off-white);

  &-description {
    font-weight: 300;
    font-family: var(--font-second);
    line-height: 1.28;
  }
}

.power-section-wrapper {
  & + .redi-section {
    padding-top: 0;
  }

  &.power-section-wrapper-about {
    .bg-layer {
      opacity: 0.1;
      transform: translate(-12.6%, 6.8%);
    }
  }
}

.team-section {
  padding: ac(140px, 50px) 0 ac(140px, 40px);

  + .team-section {
    padding-top: ac(0px, 10px) !important;
  }

  .bg-layer {
    opacity: 0.1;
    width: parc(1293);
    min-width: 428px;
    max-width: 1293px;
    top: auto !important;
    left: auto !important;
    bottom: 0;
    right: 0;
    transform: translate(13%, -51%) !important;
    &:nth-of-type(2) {
      transform: translate(13%, -52%) !important;
    }
  }

  .title {
    margin-bottom: ac(59px, 35px);
  }

  &-list {
    /*display: flex;
            flex-wrap: wrap;*/
    display: flex;
    flex-wrap: wrap;
    gap: ac(30px, 20px);
    justify-content: center;

    .teammate-card {
      width: calc(33.33333% - ac(20px, 15px));
    }
  }
}

.teammate-card {
  box-shadow: 0 7px 25px 0 rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &-thumbnail {
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 20px;

    &-wrapper {
      position: relative;
      width: 100%;

      &::before {
        content: '';
        display: block;
        padding-top: 76.148%;
      }

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .btn-social {
      position: absolute;
      left: 10px;
      bottom: 10px;
      z-index: 1;
    }
  }

  &-text {
    --mt: 40px;
    width: 100%;
    flex-grow: 1;
    padding: calc(20px + var(--mt)) ac(30px, 20px) 30px;
    margin-top: calc(-1 * var(--mt));
    background: var(--white);
    transition: 0.3s;
  }

  &-name {
    font-size: ac(25px, 20px);
    font-weight: 400;
    line-height: 1.3;
    margin-bottom: 10px;
  }

  &-position {
    line-height: 1.219;
    font-size: 17px;
    margin-bottom: 19px;
  }

  &-description {
    --line-count: 7;
    --fz: 18px;
    --line-height: 1.296;
    line-height: var(--line-height);
    font-size: var(--fz);
    font-family: var(--font-second);
    /* max-height: calc(var(--fz) * var(--line-height) * var(--line-count));*/
    font-weight: 300;
    @mixin max-line-length 3;

    &.simplebar-scrollable-y {
      padding-right: 15px;
    }
  }

  &:hover {
    .teammate-card {
      &-text {
        background: var(--deep-blue);
        color: var(--off-white);
      }
    }
  }
}

.redi-section.redi-section-about {
  padding-top: 20px;
  margin-top: -20px;
  padding-bottom: ac(139px, 40px);
  overflow: hidden;
  .bg-layer {
    top: 0;
    left: 0;
    transform: translate(-12%, 15.7%);
  }
}

.join-team-section {
  padding: ac(110px, 60px) 0;
  @media (min-width: 1441px) {
    padding: ac(190px, 60px, 1441, 2560) 0;
  }

  .section-bg {
    img {
      object-position: center 27%;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .title {
    padding: 0px 30px 7px;
    background: var(--off-white);
    border-radius: ac(61px, 50px);
    width: fit-content;
    margin-bottom: ac(42px, 35px);
    font-size: ac(70px, 33px, 320);
  }
}

@media (max-width: 992px) {
  .team-section-list {
    gap: 30px;

    .teammate-card {
      width: calc(50% - 20px);
    }
  }
}

@media (max-width: 650px) {
}

@media (max-width: 600px) {
  .team-section {
    .title {
      text-align: center;
    }
  }

  .team-section-list {
    .teammate-card {
      width: 394px;
      max-width: 100%;
    }
  }
}

@media (max-width: 370px) {
  .hero-section-interior {
    .title {
      font-size: 50px;
    }
  }
}

.teammate-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: none;

  &-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--midnight);
    opacity: 0.6;
  }

  &-container {
    width: 606px;
    max-width: calc(100% - 30px);
    max-height: calc((var(--vh, 1vh) * 100) - 40px);
    background: var(--white);
    box-shadow: 0 7px 25px 0 rgba(0, 0, 0, 0.06);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    border-radius: 20px;
    overflow: hidden;
    padding: ac(14px, 40px) ac(30px, 20px) 30px;
    display: flex;
    animation: fadeDown forwards 0.3s;

    .simplebar-track.simplebar-vertical {
      top: 50px;
    }

    .close-btn {
      position: absolute;
      right: ac(30px, 10px);
      top: ac(30px, 10px);
      z-index: 2;
    }

    &-inner {
      max-height: 100%;
      width: 100%;
    }
  }
}

.teammate-popup-card {
  &-header {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;

    &-text {
      flex-grow: 1;
      min-width: 0;
      padding-top: 17px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    a {
      &:not([class]) {
        font-size: ac(18px, 16px);
        font-family: var(--font-second);
        font-weight: 300;
        line-height: 1.25;
        margin-bottom: 18px;
        transition: all 0.3s ease;

        &:hover {
          color: var(--sky-blue);
        }
      }
    }
  }

  &-thumbnail {
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    width: ac(224px, 150px);
    flex-shrink: 0;

    &::before {
      content: '';
      display: block;
      padding-top: 126.33%;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
    }
  }

  &-title {
    font-size: ac(35px, 25px);
    line-height: 1.26;
    margin-bottom: 16px;
  }

  &-position {
    font-size: ac(25px, 20px);
    line-height: 1.26;
    margin-bottom: 16px;
  }

  &-description {
    font-size: ac(18px, 16px);
    font-family: var(--font-second);
    font-weight: 300;
    line-height: 1.28;

    p {
      margin-bottom: 15px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 650px) {
  .teammate-popup-card {
    &-thumbnail {
      width: 300px;
      max-width: 70%;
    }

    &-header {
      flex-direction: column;
      align-items: center;
      text-align: center;

      &-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }
    }
  }
}
