.solutions-page {
  .hero-section {
    .title {
      margin-bottom: 31px;
    }
  }
  .power-section-wrapper {
    .bg-layer {
      top: 263px;
      left: -1px;
    }
  }
}

.power-section-deliver {
  .section-descr {
    font-size: ac(18px, 16px);
    line-height: 1.25;
    margin-bottom: ac(67px, 30px);

    p {
      margin-bottom: 17px;
    }
  }
}

.sol-section {
  padding: ac(92px, 40px) 0 ac(70px, 35px);

  .title {
    margin-bottom: ac(60px, 35px);
  }
}

.sol-swiper-wr {
  .swiper {
    overflow: visible;
    &-slide {
      height: auto;
      display: flex;
      @media (max-width: 1200px) {
        width: 375px;
        max-width: 100%;
      }
    }

    &-button {
      margin-top: 35px;
    }
  }
}

.sol-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  padding: ac(36px, 26px) ac(30px, 15px) ac(30px, 25px);
  color: var(--off-white);
  transform: translate(0);

  &-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
    border-radius: 20px;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      border-radius: 20px;
      transform: translateZ(0);
    }

    &::before {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.5) 100%
      );
    }

    &::after {
      background: var(--ice-blue);
      mix-blend-mode: multiply;
      z-index: 2;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
      z-index: 0;
      filter: saturate(0);
      will-change: filter;
      border-radius: 20px;
      transform: translateZ(0);
    }
  }

  &-header {
    display: flex;
    gap: 17px;
    align-items: center;
    margin-bottom: 28px;
  }

  &-logo {
    width: 33px;
    height: 33px;
    display: grid;
    flex-shrink: 0;
    place-items: center;
    transform: translateY(-5%);

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &-title {
    font-size: ac(32px, 20px);
    font-weight: 600;
    line-height: 1.344;
  }

  &-description {
    --mb: 15px;
    --mb-count: 1;
    --line-count: 10;
    --fz: ac(18px, 16px);
    --line-height: 1.26;
    line-height: var(--line-height);
    font-size: var(--fz);
    font-family: var(--font-second);
    font-weight: 300;
    margin-bottom: ac(70px, 35px);
    width: 100%;
    max-height: calc(
      var(--fz) * var(--line-height) * var(--line-count) + var(--mb) *
        var(--mb-count)
    );

    p {
      margin-bottom: var(--mb);
      transition: 0.3s;
      padding-bottom: 1px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    gap: 20px;
    width: 100%;

    .btn {
    }
  }

  &:hover {
    .sol-card {
      &-bg {
        img {
          transform: translateZ(0) scale(1.05);
        }
      }
      &-description {
        text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
      }
    }
  }
}

.projects-section {
  padding: ac(70px, 35px) 0 ac(110px, 50px);
  overflow: hidden;

  .bg-layer {
    bottom: 0;
    right: 0;
    transform: translate(12.6%, 93.3%);
  }

  .title-wr {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
    align-items: center;
    margin-bottom: ac(59px, 30px);

    .title {
      margin-bottom: 0;
    }

    .swiper-buttons {
      margin-left: auto;
      margin-right: 0;
      @media (min-width: 1280px) {
        transform: translateY(8px);
      }
    }
  }
}

.projects-swiper-wr {
  .swiper {
    overflow: visible;
    &-slide {
      display: flex;
      height: auto;
    }
  }
}

.project-card {
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  width: 100%;

  &-thumbnail {
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    margin-bottom: 20px;

    &::before {
      content: '';
      display: block;
      padding-top: 56.09%;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
    }
  }
  &-text {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &-title {
    font-size: ac(25px, 20px);
    font-weight: 400;
    line-height: 1.26;
    margin-bottom: 21px;
  }

  &-description {
    @mixin max-line-length 3;
    font-size: ac(18px, 16px);
    line-height: 1.25;
    margin-bottom: ac(31px, 25px);
    font-family: var(--font-second);
    font-weight: 300;
  }

  .btn-link {
    font-size: 16px;
    margin-top: auto;
  }

  &:hover {
    color: var(--deep-blue);

    img {
      width: 105%;
      height: 105%;
      max-width: revert;
    }
  }
}

@media (max-width: 420px) {
  .projects-section .title-wr {
    align-items: center;
    text-align: center;
    .swiper-buttons {
      margin-right: auto;
    }
  }
}

.hero-section.solutions-type {
  @media (min-width: 651px) {
    min-height: 700px;
  }
  @media (min-width: 1441px) {
    min-height: ac(1150px, 700px, 1441, 2560);
    .cont {
      justify-content: center;
    }
  }
}
