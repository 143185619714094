.fp-watermark {
  display: none;
}

.swiper-wrapper {
  left: 0 !important;
}

#site {
  width: 100%;
  opacity: 1;
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: calc(100 * var(--vh));
}

.main {
  width: 100%;
  flex-grow: 1;
}

.cont {
  margin: 0 auto;
  max-width: 1290px;
  width: perc(1290);
  padding: 0 24px;

  @media (max-width: 1100px) {
    width: 100%;
  }
  @media (max-width: 374px) {
    padding: 0 15px;
  }
}

section {
  position: relative;
}

.logo {
  transition: 0.3s ease;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:hover {
    transform: scale(1.06);
  }
}

#bold-credits {
  width: 148px;
  transition: ease-in-out 0.25s;

  &::before,
  &::after {
    display: none;
  }

  &:hover {
    transform: scale(1.1);
  }

  @mixin max-sm {
    width: 159px;
  }
}

#site.cookie-terms-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  .main {
    flex-grow: 1;
    padding: 0;
  }
}

.cookie-terms-section {
  padding: ac(200px, 120px) 0 ac(100px, 50px);

  p {
    padding: 0;
    margin-bottom: 10px;
  }

  ul,
  ol {
    padding-left: 20px;

    li {
      p {
        margin-bottom: 0;
      }
    }
  }

  ul {
    list-style: circle;
  }

  ol {
    list-style: decimal;
  }

  a {
    display: inline;
    text-decoration: underline;
    transition: 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }

  table {
    border: 1px solid var(--midnight);
    width: 100% !important;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    tr,
    thead {
      &:not(:last-child) {
        border-bottom: 1px solid var(--midnight);
      }
    }

    td,
    th {
      padding: 10px;

      @include media(1100) {
        padding: 8px;
      }

      @include media(769) {
        padding: 6px;
      }

      @include media(551) {
        padding: 4px;
      }

      &:not(:last-child) {
        border-right: 1px solid var(--midnight);
      }
    }
  }
}

.single-page-404 {
  min-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    margin-bottom: ac(35px, 20px);
  }

  p {
    margin-bottom: 15px;
    padding: 0;
  }

  a {
    margin-top: 20px;
  }
}

.bg-layer {
  position: absolute;
  z-index: -1;
  &.bg-layer-main {
    opacity: 0.1;
    width: perc(1293);
    min-width: 428px;
    max-width: 1293px;
  }
}

.section-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;

  img,
  video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  video {
    z-index: 1;
  }
}

.bg-sky-blue {
  background: var(--sky-blue);
}

.bg-ice-blue {
  background: var(--ice-blue);
}

.bg-deep-blue {
  background: var(--deep-blue);
}

.text-sky-blue {
  color: var(--sky-blue);
}

.text-ice-blue {
  color: var(--ice-blue);
}

.text-deep-blue {
  color: var(--deep-blue);
}

.hero-section {
  min-height: calc(100 * var(--vh, 1vh));
  color: var(--off-white);
  padding: 120px 0 ac(77px, 40px);
  display: flex;
  overflow: hidden;
  &-motto {
    font-size: ac(30px, 22px, 375);
    font-weight: 300;
    font-family: var(--font-second);
    line-height: 1.25;
    max-width: 541px;
  }

  .cont,
  .bg-layer {
    transition: 0.3s;
  }

  .section-bg {
    &::before {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
      z-index: 2;
      background: linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.82));
    }
  }

  .cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: revert;
    z-index: 1;
  }

  .btn-link {
    margin-bottom: ac(80px, 74px);
  }

  .bg-layer {
    width: perc(1021);
    max-width: 1021px;
    min-width: 430px;
    z-index: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transform: translate(40.2%, 54.2%) scale(0.8);
    will-change: transform;
    &.aos-animate {
      opacity: 1;
      transform: translate(40.2%, 54.2%) scale(1);
    }

    img {
      width: 100%;
      height: auto;
    }
  }
  .socials-buttons {
    margin-bottom: 14px;
  }
}

.hero-caption-form {
  width: 490px;
  max-width: 100%;

  .input-wr {
    width: 100%;

    input {
      width: 100%;
    }
  }
}

.show-menu {
  .hero-section {
    .cont,
    .bg-layer {
      opacity: 0;
    }
  }
}

.hero-section.hero-section-interior {
  min-height: ac(1010px, 600px, 1440, 2560);
  display: flex;
  /*align-items: center;*/

  /*padding-top: ac(101px, 120px);*/
  padding-top: ac(239px, 200px);
  @media (max-height: 600px) {
    min-height: calc(100 * var(--vh, 1vh));
  }
  .cont {
    justify-content: flex-start;
    @media (max-width: 650px) {
      justify-content: center;
    }
  }
  .title {
    font-size: ac(90px, 40px, 320);
  }

  .bg-layer {
    width: perc(1061);
    max-width: 1061px;
    min-width: 250px;
    transform: translate(37.8%, 47.4%) scale(0.8);
    will-change: transform;
    transition: all 0.3s 0.1s !important;
    opacity: 0;
    &.aos-animate {
      opacity: 1;
      transform: translate(37.8%, 47.4%) scale(1);
    }
  }

  .section-bg {
    background: linear-gradient(99.85deg, #011472 0%, #040d1d 100%);

    &::before {
      display: none;
    }

    &-img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      overflow: hidden;
      mix-blend-mode: screen;
      opacity: 0.4;

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s;
        @media (max-width: 650px) {
          object-position: 59% top;
        }
      }
    }

    &-layer {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      mix-blend-mode: multiply;
      opacity: 0.2;
      z-index: 1;

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s;
      }
    }
  }

  .btn-link {
    margin: 0;
  }
  @media (max-width: 650px) {
    min-height: calc(100 * var(--vh, 1vh));
  }
}

.hero-section.hero-section-interior-white {
  min-height: 0;
  padding-top: 238px;
  color: var(--midnight);
  overflow: visible;
  .bg-layer {
    bottom: auto;
    top: 0;
    transform: translate(37.7%, -42.8%) scale(0.9);
    transition: 0.3s 0.1s !important;
    opacity: 0;
    width: perc(1060);
    max-width: 1060px;
    &.aos-animate {
      /*transform: translate(49.2%, -39%);*/
      transform: translate(37.7%, -42.8%) scale(1);
      opacity: 1;
    }
  }
}

.mix-sky-blue {
  img {
    filter: saturate(0);
  }
  &::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 3;
    opacity: 0.2;
    background: #000;
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    z-index: 2;
    background: var(--ice-blue);
    mix-blend-mode: multiply;
  }
}

.title-markers {
  span {
    color: var(--sky-blue);
  }
}

.pass-sections-wrapper {
  position: relative;
}

@media (max-width: 650px) {
  .hero-section.hero-section-interior {
    padding-top: 100px;
  }
}
.external-form-section {
  padding: ac(80px, 35px) 0;
}
.success-title {
  grid-column: 1/-1;
  font-size: ac(50px, 20px);
  margin: 25px 0;
}
