.footer {
  padding: ac(77px, 41px) 0 34px;
  color: var(--off-white);
  background: var(--black);

  &-nav {
    display: flex;
    gap: 20px;
    padding-bottom: ac(168px, 40px);

    .btn {
      align-self: flex-start;
      margin-right: ac(149px, 0px, 1200);
    }
  }

  a:not([class]) {
    transition: color 0.3s;

    &:hover {
      color: var(--sky-blue);
    }
  }



  &-menu {
    flex-shrink: 0;
    width: ac(190px, 145px, 1200);

    span {
      display: block;
      font-size: 20px;
      line-height: 1.25;
      margin-bottom: ac(12px, 15px);
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: flex-start;

      a {
        font-family: var(--font-second);
        line-height: 1.277;
        font-size: ac(18px, 16px);
        @media (max-width: 650px){
          line-height: 1.25;
          }
      }
    }
  }
  &-email{
    font-size: ac(30px, 18px);
    font-weight: 400;
    font-family: var(--font-second);
    margin-top: 45px;
    display: inline-block;
    transition: 0.3s;
    &:hover{
      color: var(--sky-blue);
    }
  }

 &-copyright{
   display: flex;
   justify-content: space-between;
   padding-bottom: 40px;
   font-size: 14px;
   line-height: 1.28;
   flex-wrap: wrap;
   gap: 20px;
   &-navbar{
     display: flex;
     flex-wrap: wrap;
     gap:ac(20px, 20px);
   }
 }

  &-logos-wrapper{
    display: flex;
    flex-wrap: wrap;
    gap: ac(36px, 20px);
    padding-bottom: 40px;
  }

  &-cert-logos{

    display: flex;
    justify-content: flex-end;
    gap: 18px;
    align-items: center;

    img{
      max-width: 50px;
      height: 50px;
      object-fit: contain;
    }
  }

  .logo {
    width: ac(171px, 260px);
    flex-shrink: 0;
    align-self: flex-end;
    margin-right: auto;

    &::before {
      display: none;
    }

    &:hover {
      transform: scale(1.06);
    }
  }
}

.office-swiper-wr {
  flex-grow: 1;
  .swiper{
    overflow: visible;
  }

  .swiper:not(.swiper-initialized) {
    .swiper-wrapper {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: ac(30px, 15px) ac(31px, 20px);
    }

    & + .swiper-buttons {
      display: none;
    }
  }
}

.office-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &-title {
    font-size: 20px;
    line-height: 1.27;
    color: var(--ice-blue);
    margin-bottom: 12px;
  }

  a {
    display: inline-block;
    margin-bottom: 9px;
    font-family: var(--font-second);
    line-height: 1.277;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 1200px) {
  .footer{

    &-nav{
      flex-direction: column;
      align-items: center;
      gap: 40px;
      .btn{
        margin: 0 auto;
      }
    }
    &-menu{

      width: 100%;
      margin: 0 auto;

      ul{
        flex-direction: row;
        flex-wrap: wrap;
        height: auto;
        gap: 12px 55px;

      }
    }
  }
  .office-swiper-wr {
    width: 100%;
    .swiper:not(.swiper-initialized) .swiper-wrapper{
      width: 100%;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-gap: 20px;
    }
  }
}

@media (max-width: 992px) {
  .office-swiper-wr{
    margin-top: -12px;
    .swiper-buttons{
      margin-top: 23px;
      margin-left: 0;
    }
  }
  .office-card {
    &-title{
      margin-bottom: 19px;
    }
    a{
      font-size: 16px;
      margin-bottom: 12px;
    }
  }
}

@media (max-width: 450px) {
  .footer{
    &-menu{
      margin-left: 0;
      width: 327px;
      margin: 0 auto;
      ul{
        align-content: flex-start;
        flex-wrap: wrap;
        height: 120px;
        gap: 12px 55px;
        flex-direction: column;
      }
    }
    &-logos-wrapper{
      gap: 32px;
      .socials-buttons{
        margin-top: -5px;
        gap: 12px;
      }

    }
    &-cert-logos{
      width: 100%;
      justify-content: flex-start;
      img{
        width: 69px;
        height: 70px;
        object-fit: contain;
        max-width:100%;
        max-height: revert;
      }
    }
    &-copyright{
      &-navbar{
        flex-direction: column;
        width: 100%;
        height: 63px;
        gap: 13px 7px;
        align-items: flex-start;
      }
    }
  }
}