.solution-details-page {
  .hero-section {
    z-index: 2;
    @media (min-width: 651px) {
      min-height: 700px;
    }
    @media (min-width: 1441px) {
      min-height: ac(1150px, 700px, 1441, 2560);
      .cont {
        justify-content: center;
      }
    }

    .section-bg {
      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: var(--midnight);
        z-index: 5;
        opacity: 0.4;
      }

      &::after {
        z-index: 4;
        background: var(--ice-blue);
        opacity: 1;
        mix-blend-mode: multiply;
      }

      &-img {
        opacity: 1;
      }
    }
  }
}

.solution-about-section {
  padding: ac(110px, 40px) 0 ac(50px, 35px);

  .bg-layer {
    top: 0;
    left: 0;
    transform: translate(-13%, -1%);
  }

  .title {
    /*max-width: 55%;*/
    max-width: 62%;
    margin-bottom: ac(-41px, 0px);
  }

  &-description {
    max-width: 818px;
    width: 66%;
    margin-left: auto;
    font-family: var(--font-second);
    font-size: 18px;
    line-height: 1.25;
    font-weight: 300;

    p:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }
}

.why-section {
  padding: ac(45px, 35px) 0 ac(140px, 30px);

  .title {
    font-size: ac(55px, 30px);
    margin-bottom: ac(79px, 35px);
  }

  &-thumbnail {
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 20px;

    &::before {
      content: '';
      display: block;
      padding-top: 94.71%;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
    }
  }

  &-tabs-wrapper {
    min-width: 0;
    flex-grow: 1;

    .btn {
      margin-top: 40px;
    }
  }

  &-tabs {
    display: flex;
    flex-direction: column;
    gap: 7px;
  }

  &-wrapper {
    display: flex;
    gap: 31px;
  }

  &-tab-item {
    padding: 21px ac(40px, 20px);
    border-radius: ac(35px, 20px);
    background: var(--off-white);
    cursor: pointer;

    &-header {
      display: flex;
      gap: 15px;
      justify-content: space-between;
      font-size: ac(32px, 22px);
      font-weight: 400;
      line-height: 1.26;
    }

    &-content {
      overflow: hidden;
      max-height: 0;
      transition: 0.5s;

      [data-simplebar] {
        --line-count: 7;
        --fz: ac(18px, 16px);
        --line-height: 1.25;
        line-height: var(--line-height);
        font-size: var(--fz);
        max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
        font-family: var(--font-second);
        font-weight: 300;

        &.simplebar-scrollable-y {
          padding-right: 10px;
        }
      }

      .simplebar-track.simplebar-vertical {
        right: 0 !important;
        transform: none;
      }
    }

    &.active {
      .why-section-tab-item-content {
        padding-top: ac(36px, 25px);
        padding-bottom: 5px;
        max-height: 300px;
      }
    }
  }
}

.why-swiper-wr {
  flex-shrink: 0;
  width: ac(605px, 320px);
  align-self: flex-start;
}

.products-section {
  background: var(--deep-blue);
  color: var(--white);
  padding: ac(110px, 50px) 0;

  .title {
    margin-bottom: ac(60px, 35px);
    color: var(--off-white);
  }

  .btn {
    margin-top: 60px;
  }
}

.products-swiper-wr {
  .swiper {
    overflow: visible;

    &-slide {
      display: flex;
      height: auto;
      overflow: visible;
      @media (max-width: 1200px) {
        width: 375px;
        max-width: 100%;
      }
    }

    &-button {
      margin-top: 35px;
    }
  }
}

.product-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: ac(40px, 20px) ac(40px, 20px) 22px;
  background: var(--ice-blue);
  border-radius: 20px;
  color: var(--midnight);

  &-header {
    display: flex;
    align-items: center;
    gap: ac(15px, 10px);
    min-height: ac(66px, 60px);
  }
  &-logo {
    display: flex;
    flex-shrink: 0;
    width: 51px;
    height: 51px;
    border-radius: 50%;
    border: 4px solid var(--midnight);
    align-items: center;
    justify-content: center;

    img {
      width: 27px;
      height: auto;
      max-width: 90%;
      max-height: 90%;
    }
  }

  &-title {
    font-weight: 600;
    font-size: ac(26px, 22px);
    line-height: 1.26;
  }

  &-description {
    --mb: 16px;
    --mb-count: 1;
    --line-count: 10;
    --fz: ac(18px, 16px);
    --line-height: 1.33;
    line-height: var(--line-height);
    font-size: var(--fz);
    max-height: calc(
      var(--fz) * var(--line-height) * var(--line-count) + var(--mb) *
        var(--mb-count)
    );
    font-family: var(--font-second);
    font-weight: 300;

    p:not(:last-of-type) {
      margin-bottom: var(--mb);
    }
  }
}

.choose-section {
  padding: ac(140px, 45px) 0;

  .bg-layer {
    top: 16px;
    left: 66%;
    transform: translate(-50%, 0);
    width: perc(1293);
    max-width: 1293px;
  }

  .title {
    margin-bottom: ac(60px, 35px);
  }
}

.choose-swiper-wr {
  .swiper {
    overflow: visible;
    width: ac(489px, 330px);
    max-width: 100%;
    margin: 0;

    &-slide {
      display: flex;
      justify-content: flex-end;
      height: auto;
      overflow: visible;

      &:nth-last-of-type(2) {
        .choose-card {
          width: calc(100% - ac(31px, 0px));
          padding-right: ac(106px, 70px);
        }
      }

      &:last-of-type {
        .choose-card {
          width: calc(100% - ac(99px, 0px));
          padding-right: ac(32px, 20px);
          margin-right: ac(45px, 20px);
        }
      }

      &.swiper-slide-active {
        .choose-card {
          /*width: 100% !important;*/
        }
      }
    }

    &-buttons {
      display: none;
      @media (max-width: 1200px) {
        display: flex;
        .swiper-button {
          margin-top: 35px;
        }
      }
    }
  }
}

.choose-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ac(40px, 25px);
  box-shadow: 0 7px 25px 0px #0000000f;
  padding: ac(40px, 30px) ac(135px, 90px) ac(40px, 30px) ac(40px, 20px);
  border-radius: 20px;
  background: var(--white);
  transition: 0.3s;
  min-height: ac(363px, 280px);

  &-title {
    font-size: ac(40px, 25px);
    font-weight: 600;
    line-height: 1.26;
  }

  &-description {
    --line-count: 10;
    --fz: 18px;
    --line-height: 1.33;
    line-height: var(--line-height);
    font-size: var(--fz);
    max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
    font-family: var(--font-second);
    font-weight: 300;
  }

  &:hover {
    background: var(--ice-blue);
  }

  @media (max-width: 768px) {
    padding-right: 70px;
    margin-right: 0 !important;
    width: 100% !important;
  }
}

@media (max-width: 768px) {
  .solution-about-section {
    .title {
      max-width: 396px;
      width: 100%;
      margin-bottom: 30px;
    }

    &-description {
      width: 100%;
      max-width: 100%;
    }
  }

  .why-section {
    &-wrapper {
      flex-direction: column;
    }

    .why-swiper-wr {
      width: 400px;
      margin: 0 auto;
      max-width: 100%;
    }
  }
}
